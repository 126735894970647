import React, { useState } from "react";
import Modal from "react-modal"; // Optional: Install via npm install react-modal
import toast from "react-hot-toast";
import {
  useAddRemoveSymbolMutation,
  useGetSymbolsQuery,
} from "../../redux/exchangeApi";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    maxWidth: "600px",
    margin: "auto",
    padding: "0px",
    borderRadius: "8px",
    height: "max-content",
  },
};

export default function SymbolModal({
  isOpen,
  onRequestClose,
  segment,
  added,
  socket,
}) {
  const { data, isLoading } = useGetSymbolsQuery({ segment });
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all"); // Default tab
  const [addRemove, { isLoading: adding }] = useAddRemoveSymbolMutation();
  const [selectedPairId, setSelectedPairId] = useState();

  const handleSubmit = async (pairId, action) => {
    setSelectedPairId(pairId);

    const res = await addRemove({
      symbolId: pairId,
      action: action,
      segment: segment,
    });

    if (res.data.status) {
      toast.success(res.data.message);
      if (segment === "crypto" && socket.current) {
        socket.current.emit("coinPairList");
      }
    } else {
      toast.error(res.data.message);
    }
  };

  // Filter data based on searchTerm and selectedCategory
  const filteredData = data
    ? data
        .filter((symbol) =>
          symbol.symbol.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .filter((symbol) => {
          if (segment !== "equity" || selectedCategory === "all") return true;
          if (selectedCategory === "ind") return symbol.symbol_key === "ind";
          if (selectedCategory === "xhkg") return symbol.symbol_key === "xhkg";
          return symbol.symbol_key !== "ind" && symbol.symbol_key !== "xhkg"; // USA
        })
    : [];

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      ariaHideApp={false}
    >
      <div className="modal-header p-3 mb-0 color_new">
        <h5 className="mb-0">Add symbol</h5>
        <button className="close-btn" onClick={onRequestClose}>
          <i className="bi bi-x-lg"></i>
        </button>
      </div>
      <div className="d-flex search_box align-items-center p-3">
        <i className="bi bi-search mr-3"></i>
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
      </div>
      {/* Tabs for equity */}
      {segment === "equity" && (
        <div className="tabs d-flex justify-content-around p-3">
          <button
            className={`btn mx-1 ${selectedCategory === "all" ? "active" : ""}`}
            onClick={() => setSelectedCategory("all")}
          >
            All
          </button>
          <button
            className={`btn mx-1 ${selectedCategory === "usa" ? "active" : ""}`}
            onClick={() => setSelectedCategory("usa")}
          >
            USA
          </button>

          <button
            className={`btn mx-1 ${
              selectedCategory === "xhkg" ? "active" : ""
            }`}
            onClick={() => setSelectedCategory("xhkg")}
          >
            Hong Kong
          </button>
          <button
            className={`btn mx-1 ${selectedCategory === "ind" ? "active" : ""}`}
            onClick={() => setSelectedCategory("ind")}
          >
            Indian
          </button>
        </div>
      )}

      <div className="p-3 color_new">
        <div className="popop_table_scrool">
          <table className="symbol-table table table_border add_popop_table">
            <thead>
              <tr>
                <th>Symbol</th>
                <th>Category</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan="3">Loading...</td>
                </tr>
              ) : filteredData.length > 0 ? (
                filteredData.map((symbol, index) => (
                  <tr key={index}>
                    <td>{symbol.symbol}</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {segment === "equity"
                        ? symbol.symbol_key === "ind"
                          ? "Indian"
                          : symbol.symbol_key === "xhkg"
                          ? "Hong Kong"
                          : "USA"
                        : segment}
                    </td>
                    <td>
                      <div className="">
                        {adding && selectedPairId === symbol.pairId ? (
                          <span
                            className="loading-spin"
                            style={{
                              color: "rgb(0, 188, 139)",
                              cursor: "pointer",
                            }}
                          >
                            <div
                              className="loader"
                              style={{ display: "inline-block" }}
                            ></div>
                          </span>
                        ) : added.includes(symbol.symbol) ? (
                          <i
                            className="bi bi-trash-fill cursor-pointer"
                            onClick={() =>
                              handleSubmit(symbol.pairId, "remove")
                            }
                          ></i>
                        ) : (
                          <i
                            className="bi bi-plus-lg cursor-pointer"
                            onClick={() => handleSubmit(symbol.pairId, "add")}
                          ></i>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3">No symbols found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  );
}
