import { myApi } from "./api";
import {
  cancleOrderFx,
  closePosition,
  createOrderClick,
  modifyOrderClick,
  fetchUserLeverage,
  getForexPortfolio,
  getOpenOrderFx,
  getSymbolInfo,
  updateUserLeverage,
  getSelectedLeverage,
  partialClosePosition,
  closeAllPositions,
} from "../components/constant/Api";

export const ForexApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    createOrderClick: builder.mutation({
      query: (post) => ({
        url: createOrderClick,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.success ? response ?? response : response;
      },
    }),
    modifyOrderClick: builder.mutation({
      query: (post) => ({
        url: modifyOrderClick,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.success ? response ?? response : response;
      },
    }),
    closePosition: builder.mutation({
      query: (post) => ({
        url: closePosition,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.success ? response ?? response : response;
      },
    }),
    closeAllPositions: builder.mutation({
      query: (post) => ({
        url: closeAllPositions,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.success ? response ?? response : response;
      },
    }),
    cancleOrderFx: builder.mutation({
      query: (post) => ({
        url: cancleOrderFx,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.success ? response ?? response : response;
      },
    }),
    leverageByUser: builder.query({
      query: () => ({
        url: fetchUserLeverage,
        method: "POST",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? "" : "";
      },
    }),
    getUserForexPortfolio: builder.mutation({
      query: (post) => ({
        url: getForexPortfolio,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response ? response ?? "" : "";
      },
    }),
    updateLeverageUser: builder.mutation({
      query: (post) => ({
        url: updateUserLeverage,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.success ? response ?? response : response;
      },
    }),
    getSymbolInfo: builder.mutation({
      query: (post) => ({
        url: getSymbolInfo,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response ?? response : response;
      },
    }),
    getSelectedLeverage: builder.query({
      query: () => ({
        url: getSelectedLeverage,
        method: "POST",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? "" : "";
      },
    }),
    partialClosePosition: builder.mutation({
      query: (post) => ({
        url: partialClosePosition,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.success ? response ?? response : response;
      },
    }),
  }),
});

export const {
  useCreateOrderClickMutation,
  useClosePositionMutation,
  useCancleOrderFxMutation,
  useLeverageByUserQuery,
  useUpdateLeverageUserMutation,
  useGetUserForexPortfolioMutation,
  useGetSymbolInfoMutation,
  useGetSelectedLeverageQuery,
  useModifyOrderClickMutation,
  usePartialClosePositionMutation,
  useCloseAllPositionsMutation,
} = ForexApi;
