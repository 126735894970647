import React, { useEffect, useRef, useState, useCallback } from "react";
import socketIOClient from "socket.io-client";
import ExchangeNavBar from "../widgets/ExchangeNavBar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { imageUrl, socketUrl } from "../constant/BaseUrl";
import DocumentMeta from "react-document-meta";
import { ProductName } from "../constant/ProductName";
import TradingViewForexWidgit from "../partials/TradingViewForexWidgit";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import Decimal from "decimal.js";
import toast from "react-hot-toast";
import update from "immutability-helper";
import {
  useCancleOrderFxMutation,
  useClosePositionMutation,
  useCreateOrderClickMutation,
  useGetSelectedLeverageQuery,
  useGetSymbolInfoMutation,
  useModifyOrderClickMutation,
  usePartialClosePositionMutation,
  useCloseAllPositionsMutation,
} from "../../redux/forexApi";
import moment from "moment";
import BigNumber from "bignumber.js";
import { useGetallUserQuery } from "../../redux/userApi";
import DraggableItem from "./DraggableItem";
import Modal from "react-bootstrap/Modal";
import { useGetUserWidgetsQuery } from "../../redux/userApi";
import "../../App.css";
import pako from "pako";
import { Buffer } from "buffer";
import { useTranslation } from "react-i18next";
import { formatDecimal, formatTakeProfit } from "../../util/decimalFormatter";
import UserTicker from "../partials/UserTicker";
import SymbolModal from "../partials/TradingTerminalModel";
import { useAddRemoveSymbolMutation } from "../../redux/exchangeApi";
const ResponsiveGridLayout = WidthProvider(Responsive);

const layouts = {
  lg: [
    { i: "topbar", x: 0, y: 0, w: 12, h: 1.5, static: true },
    {
      i: "pairslist",
      x: 9,
      y: 0,
      w: 3,
      h: 14.4,
    },
    { i: "buy", x: 9, y: 1, w: 3, h: 19 },
    { i: "chart", x: 3, y: 1, w: 6, h: 14.4 },
    { i: "marketdepth", x: 0, y: 1, w: 3, h: 14.4 },
    { i: "orderdetails", x: 0, y: 9, w: 9, h: 19 },
  ],
  md: [
    { i: "topbar", x: 0, y: 0, w: 12, h: 1, static: true },
    {
      i: "pairslist",
      x: 9,
      y: 1,
      w: 3,
      h: 14,
    },
    { i: "buy", x: 9, y: 1, w: 3, h: 18 },
    { i: "chart", x: 0, y: 1, w: 7, h: 14 },
    { i: "marketdepth", x: 9, y: 1, w: 3, h: 14.4 },
    { i: "orderdetails", x: 0, y: 9, w: 7, h: 18 },
  ],
  sm: [
    { i: "topbar", x: 0, y: 0, w: 12, h: 1, static: true },
    {
      i: "pairslist",
      x: 10,
      y: 0,
      w: 2,
      h: 14.4,
      minW: 3,
      minH: 4,
    },
    { i: "buy", x: 9, y: 1, w: 2, h: 18, minW: 3, minH: 8 },
    { i: "chart", x: 0, y: 1, w: 4, h: 14.4, minW: 3, minH: 6 },
    { i: "marketdepth", x: 9, y: 1, w: 2, h: 14.4, minW: 2, minH: 8 },
    { i: "orderdetails", x: 0, y: 9, w: 4, h: 18, minW: 3 },
  ],
  xs: [
    { i: "topbar", x: 0, y: 0, w: 12, h: 6.5, static: true },
    { i: "pairslist", x: 0, y: 2, w: 12, h: 10 },
    { i: "chart", x: 0, y: 1, w: 12, h: 12 },
    { i: "buy", x: 0, y: 3, w: 12, h: 14, minW: 3, minH: 8, setIndex: 6 },
    { i: "marketdepth", x: 0, y: 13, w: 12, h: 10 },
    { i: "orderdetails", x: 0, y: 18, w: 12, h: 16 },
  ],
};
const symbolCountryMap = {
  N: "USA",
  O: "USA",
  xhkg: "Hong Kong",
  ind: "India",
  // Add other mappings as needed
};

const getFromLocalStorage = (key) => {
  if (typeof window !== "undefined") {
    const saved = localStorage.getItem(key);
    return saved ? JSON.parse(saved) : null;
  }
  return null;
};

const saveToLocalStorage = (key, value) => {
  if (typeof window !== "undefined") {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

const defaultError = {
  price: "",
  stopPrice: "",
  takeProfit: "",
};

const defaultPairs = [
  "AAPL",
  "AMZN",
  "GOOGL",
  "MRNA",
  "NFLX",
  "NVDA",
  "QCOM",
  "TSLA",
  "UBER",
  "135",
  "1398",
  "144",
  "151",
  "16",
  "1658",
  "168",
  "17",
  "175",
  "ABCAPITAL",
  "ABB",
  "IRFC",
  "DEEPAKNTR",
  "SETFNN50",
  "JIOFIN",
  "HDFCVALUE",
  "JPPOWER",
  "NTPC",
  "BAJAJFINSV",
];

export const ContextMenu = ({ x, y, onAction, menuRef }) => {
  return (
    <div ref={menuRef} className="context-menu bg2" style={{ top: y, left: x }}>
      <button onClick={() => onAction("close")}>
        <span>Close</span> <i className="bi bi-x-circle"></i>
      </button>
      <button onClick={() => onAction("modify")}>
        <span>Modify</span> <i className="bi bi-pencil-square"></i>
      </button>
      {/* <button onClick={() => onAction("Action 2")}>Modify</button> */}
    </div>
  );
};

const ForexExchangeEquity = (props) => {
  const { t } = useTranslation();
  const localSocket = useRef(null);
  const { symbol } = useParams();
  const { data } = useGetallUserQuery();
  const navigate = useNavigate();
  const [getSymbolInfo] = useGetSymbolInfoMutation();
  const [createOrderOneClick, { isLoading }] = useCreateOrderClickMutation();
  const { data: selectedLeverages } = useGetSelectedLeverageQuery();
  const [modifyOrderClick] = useModifyOrderClickMutation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedWatchList, setSelectedWatchList] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [closeOrderFx, { isLoading: positionClosing }] =
    useClosePositionMutation();
  const [closeAllPositions, { isLoading: positionAllClosing }] =
    useCloseAllPositionsMutation();
  const [partialOrderClose, { isLoading: partialLoading }] =
    usePartialClosePositionMutation();
  const { data: userWidgets, isLoading: loadingWidgets } =
    useGetUserWidgetsQuery({ type: "equity" });
  const [cancleOrderFx] = useCancleOrderFxMutation();
  const [cancleOrders, setCancleOrders] = useState([]);
  const [coinPairData, setCoinPairData] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [currentPrice, setCurrentPrice] = useState("");
  const [isOrderModelOpen, setIsOrderModelOpen] = useState(false);
  const [sideAcvive, setSideAcvive] = useState("buy");
  const [theme, setTheme] = useState("dark");
  const [activeOrderTab, setActiveOrderTab] = useState("positions");
  const [openOrders, setOpenOrders] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [closedOrders, setClosedOrders] = useState([]);
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState(1.0);
  const [selectedAsk, setSelectedAsk] = useState("");
  const [selectedBid, setSelectedBid] = useState("");
  const [stopPrice, setStopPrice] = useState("");
  const [isStopLoss, setIsStopLoss] = useState(false);
  const [userBalance, setUserBalance] = useState("0.00");
  const [allOrdersData, setAllOrdersData] = useState([]);
  const [margin, setMargin] = useState("0.00");
  const [freeMargin, setFreeMargin] = useState("0.00");
  const [marginLevel, setMarginLevel] = useState("0.00");
  const [userLeverage, setUserLeverage] = useState("");
  const [floatingProfits, setFloatingProfits] = useState(new BigNumber(0));
  const [refresh, setRefresh] = useState(false);
  const [floatingProfitsClose, setFloatingProfitsClose] = useState(
    new BigNumber(0)
  );
  const [accountPnL, setAccountPnL] = useState(new BigNumber(0));
  const [selectedFilter, setSelectedFilter] = useState("USA");
  const [takeProfit, setTakeProfit] = useState("");
  const [isTakeProfit, setIsTakeProfit] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [orderMethod, setOrderMethod] = useState("market");
  const [currentOrderClosing, setCurrentClosingOrder] = useState(null);
  const [notAllowedWidgets, setNotAllowedWidgets] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [feeAmyt, setFeeAmyt] = useState("");
  const [percentAmount, setPercentAmount] = useState(0);
  const [buyCommission, setBuyCommission] = useState(0);
  const [sellCommission, setSellCommission] = useState(0);
  const [feesType, setFeesType] = useState("");
  const [monetary, setMonetary] = useState(new BigNumber(0));
  const [visibleItems, setVisibleItems] = useState([]);
  const [invisibleItems, setInvisibleItems] = useState([]);
  const [addRemove, { isLoading: adding }] = useAddRemoveSymbolMutation();
  const [orderId, setOrderId] = useState("");
  const [displayPrice, setDisplayPrice] = useState("");
  const [priceMap, setPriceMap] = useState({});
  const [activeTab, setActiveTabModify] = useState("modify");
  const [volumeToClose, setVolumeToClose] = useState(1);

  useEffect(() => {
    if (selectedLeverages) {
      if (selectedLeverages) {
        const equityLeverage =
          selectedLeverages.forex_leverage_amount ||
          selectedLeverages.global_leverage ||
          1;
        setUserLeverage(equityLeverage);
      } else {
        setUserLeverage(1);
      }
    }
  }, [selectedLeverages]);

  // useEffect(() => {
  //   // console.log(userWidgets, "userWidgets");
  //   if (userWidgets) {
  //     const disabled = Object.keys(userWidgets).filter(
  //       (key) =>
  //         userWidgets[key] === "0" && key !== "positions" && key !== "trades"
  //     );
  //     if (disabled.length) {
  //       setNotAllowedWidgets(disabled);
  //     }
  //     resetLayout("forex-equity");
  //     // console.log("disabled", disabled);
  //   }
  // }, [userWidgets]);

  useEffect(() => {
    if (userWidgets) {
      const disabled = Object.keys(userWidgets).filter(
        (key) => userWidgets[key] === "0"
      );
      if (disabled.length) {
        const removeItemsLocal = getFromLocalStorage("frl") || [];

        setNotAllowedWidgets(disabled);
        let widgetToBeRemoved = [
          ...new Set(
            removeItemsLocal.filter((item) => disabled.includes(item))
          ),
        ];

        let widgetToBeAdded = [
          ...new Set(
            removeItemsLocal.filter((item) => !disabled.includes(item))
          ),
        ];

        widgetToBeAdded.map((item) => addItem(item));

        saveToLocalStorage("frl", widgetToBeRemoved);
      }

      if (!disabled.length) {
        const removeItemsLocal = getFromLocalStorage("frl") || [];
        removeItemsLocal.map((item) => addItem(item));

        saveToLocalStorage("frl", []);
      }
    }
  }, [userWidgets]);

  const handleDoubleClick = (rowData) => {
    if (positionClosing) return;
    setSideAcvive(rowData.orderSides.toLowerCase());
    setPrice(rowData.avgPrice);
    setOrderId(rowData.orderIds);
    setDisplayPrice(rowData.avgPrice);
    setStopPrice(rowData.stop_price);
    setTakeProfit(rowData.take_profit);
    setSelectedRowData(rowData);
    setShow(true);
    handleSocketMessages(localSocket, rowData.symbol);
  };

  const handleClose = () => {
    setShow(false);
    // setPrice("");
    // setStopPrice("");
    setTakeProfit("");
    setSelectedRowData(null);
    setActiveTabModify("modify");
    setVolumeToClose(1);
    handleSocketMessages(localSocket, symbol);
  };

  const username = data?.user_name || "N/A";
  const [layout, setLayout] = useState(
    getFromLocalStorage("layouts") || layouts
  );
  const [removedItems, setRemovedItems] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [marketDepth, setMarketDepth] = useState([
    {
      MDEntryType: "Bid",
      MDEntryPx: 0.85915,
      MDEntrySize: 500000,
      QuoteEntryID: "0",
    },
    {
      MDEntryType: "Bid",
      MDEntryPx: 0.85914,
      MDEntrySize: 1600000,
      QuoteEntryID: "1",
    },
    {
      MDEntryType: "Bid",
      MDEntryPx: 0.85913,
      MDEntrySize: 4100000,
      QuoteEntryID: "2",
    },
    {
      MDEntryType: "Bid",
      MDEntryPx: 0.85912,
      MDEntrySize: 3000000,
      QuoteEntryID: "3",
    },
    {
      MDEntryType: "Bid",
      MDEntryPx: 0.85911,
      MDEntrySize: 3500000,
      QuoteEntryID: "4",
    },
    {
      MDEntryType: "Offer",
      MDEntryPx: 0.8593,
      MDEntrySize: 500000,
      QuoteEntryID: "0",
    },
    {
      MDEntryType: "Offer",
      MDEntryPx: 0.85932,
      MDEntrySize: 4600000,
      QuoteEntryID: "1",
    },
    {
      MDEntryType: "Offer",
      MDEntryPx: 0.85933,
      MDEntrySize: 16000000,
      QuoteEntryID: "2",
    },
    {
      MDEntryType: "Offer",
      MDEntryPx: 0.85934,
      MDEntrySize: 10500000,
      QuoteEntryID: "3",
    },
    {
      MDEntryType: "Offer",
      MDEntryPx: 0.85935,
      MDEntrySize: 5000000,
      QuoteEntryID: "4",
    },
  ]);
  const [currentRightClickData, setCurrentRightClickData] = useState(null);
  const [errors, setErrors] = useState(defaultError);
  const menuRef = useRef(null);

  useEffect(() => {
    const fetch = async () => {
      const { data } = await getSymbolInfo({ symbol });
      if (data.status) {
        setSelectedBid(data.data.best_bid);
        setSelectedAsk(data.data.best_ask);
        setPrice(data.data.best_bid);
      }
    };
    fetch();
  }, [symbol]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLayoutChange = (layout, allLayouts) => {
    setLayout(allLayouts);
    saveToLocalStorage("layouts", allLayouts);
  };

  useEffect(() => {
    const savedLayouts = getFromLocalStorage("layouts");
    if (savedLayouts) {
      setLayout(savedLayouts);
    }
  }, []);

  useEffect(() => {
    if (removedItems.length > 0) {
      saveToLocalStorage("frl", removedItems);
    }
  }, [removedItems]);

  useEffect(() => {
    const removeItemsLocal = getFromLocalStorage("frl");
    if (removeItemsLocal) {
      setRemovedItems(removeItemsLocal);
    }
  }, []);

  useEffect(() => {
    if (!localSocket.current) {
      const mainState = {
        symbolType: "equity",
      };
      const symbolData = {
        symbol: symbol,
        symbolType: "forex",
      };
      localSocket.current = socketIOClient(socketUrl, {
        transports: ["websocket"],
        query: {
          token: localStorage.getItem("jwtToken"),
        },
      });
      localSocket.current.on("connect", () => {
        console.log("Socket is connected");
        localSocket.current.emit("ping");
        localSocket.current.emit("getEquitySymbol");
        localSocket.current.emit("userPendingOrderFX", mainState);
        localSocket.current.emit("userCloseOrderFX", mainState);
        localSocket.current.emit("userOpenOrderFX", mainState);
        localSocket.current.emit("userCancleOrderFX", mainState);
        localSocket.current.emit("userBalanceById", mainState);
        localSocket.current.emit("subscribe", { symbol, type: "eqFx" });
        localSocket.current.emit("userAllOrdeEQ");
        localSocket.current.emit("notificationLogsEQ");
        localSocket.current.emit("getCommissionBySymbol", symbolData);
        localSocket.current.emit("userAllOrdersPandL", {
          symbolType: "equity",
        });
        handleSocketMessages(localSocket, symbol);
      });
    }
    return () => {
      if (localSocket.current && !window.location.pathname.includes("/forex")) {
        console.log("WebSocket connection will be closed on unmount.");
        localSocket.current.disconnect();
      }
    };
  }, []);

  const mergeDataWithOrder = (newData, savedOrder) => {
    const orderMap = new Map();
    savedOrder.forEach((id, index) => {
      orderMap.set(id, index);
    });

    newData.sort((a, b) => {
      const orderA = orderMap.get(a.id) ?? newData.length;
      const orderB = orderMap.get(b.id) ?? newData.length;
      return orderA - orderB;
    });

    return newData;
  };

  const handleSocketMessages = (localSocket, selectedSymbol) => {
    localSocket.current.on("pong", () => {});
    localSocket.current.off("notificationLogsResultEq");
    localSocket.current.on("notificationLogsResultEq", (data) => {
      setNotificationData(data);
    });
    localSocket.current.off("resultEquitySymbol");
    localSocket.current.on("resultEquitySymbol", (compressedData) => {
      const compressedBuffer = Buffer.from(compressedData, "base64");
      const decompressedData = pako.inflate(compressedBuffer, { to: "string" });
      const data = JSON.parse(decompressedData);
      const selectedSymbolData = data.filter(
        (sym) => sym.symbol === selectedSymbol
      );
      if (selectedSymbolData) {
        setSelectedBid(selectedSymbolData[0]?.best_bid);
        setSelectedAsk(selectedSymbolData[0]?.best_ask);
      }

      const priceDataMap = {};
      data.forEach((coin) => {
        const key = `${coin.symbol}`;
        priceDataMap[key] = {
          best_bid: coin.best_bid,
          best_ask: coin.best_ask,
        };
      });
      setPriceMap(priceDataMap);

      const savedOrder = JSON.parse(localStorage.getItem("fso")) || [];
      const mergedData = mergeDataWithOrder(data, savedOrder);

      const onlyNames = [
        ...new Set(
          mergedData.filter((item) => !defaultPairs.includes(item.symbol))
        ),
      ].map((item) => item.symbol);
      // console.log(onlyNames, "onlyNames", mergedData);
      setSelectedWatchList(onlyNames);

      setCoinPairData(mergedData);
    });
    localSocket.current.off("userPendingOrderFXResult");
    localSocket.current.on("userPendingOrderFXResult", (data) => {
      setPendingOrders(data);
    });
    localSocket.current.off("userCloseOrderFXResult");
    localSocket.current.on("userCloseOrderFXResult", (data) => {
      setClosedOrders(data);
    });
    localSocket.current.off("userOpenOrderFXResult");
    localSocket.current.on("userOpenOrderFXResult", (data) => {
      setOpenOrders(data.orders);
      // setFloatingProfits(data.totalFloatingProfit);
      // setAccountPnL(data.accountProfitLoss);
    });

    localSocket.current.off("resultAllOrdersPandL");
    localSocket.current.on("resultAllOrdersPandL", (data) => {
      setFloatingProfits(data.totalFloatingProfit);
      setAccountPnL(data.accountProfitLoss);
    });
    localSocket.current.off("userCancleOrderFXResult");
    localSocket.current.on("userCancleOrderFXResult", (data) => {
      setCancleOrders(data);
    });
    localSocket.current.off("resultCommissionBySymbol");
    localSocket.current.on("resultCommissionBySymbol", (data) => {
      setFeesType(data.type ? data.type : "");
      setBuyCommission(data.buyFees ? data.buyFees : "0");
      setSellCommission(data.sellFees ? data.sellFees : "0");
    });
    localSocket.current.off("resultUserBalanceById");
    localSocket.current.on("resultUserBalanceById", (data) => {
      if (data.status) {
        const balanceMetrics = data.data;
        setUserBalance(balanceMetrics.balance);
        setMargin(balanceMetrics.equityMargin);
        setFreeMargin(balanceMetrics.freeMargin);
        setMarginLevel(balanceMetrics.marginLevel);
      } else {
        setUserBalance("0.00");
        setMargin("0.00");
        setFreeMargin("0.00");
        setMarginLevel("0.00");
      }
    });
    localSocket.current.off(`marketDepth:${symbol}`);
    localSocket.current.on(`marketDepth:${selectedSymbol}`, (data) => {
      const marketDepth = JSON.parse(data);
      setMarketDepth(marketDepth);
    });

    localSocket.current.off("userAllOrderEQResult");
    localSocket.current.on("userAllOrderEQResult", (data) => {
      setAllOrdersData(data);
    });

    localSocket.current.off("refresh");
    localSocket.current.on("refresh", () => {
      const symbolData = {
        symbol: symbol,
        symbolType: "forex",
      };
      const mainState = {
        symbolType: "equity",
      };
      localSocket.current.emit("ping");
      localSocket.current.emit("userCloseOrderFX", mainState);
      localSocket.current.emit("userCancleOrderFX", mainState);
      localSocket.current.emit("userBalanceById", mainState);
      localSocket.current.emit("userAllOrdeEQ");
      localSocket.current.emit("notificationLogsEQ");
      localSocket.current.emit("getCommissionBySymbol", symbolData);
      localSocket.current.emit("userPendingOrderFX", mainState);
      localSocket.current.emit("userOpenOrderFX", mainState);
      localSocket.current.emit("userAllOrdersPandL", {
        symbolType: "equity",
      });
      handleSocketMessages(localSocket, selectedSymbol);
    });
  };

  const onSearch = (event) => {
    setSearchedValue(event.target.value);
  };

  const EmptySearch = () => {
    setSearchedValue("");
  };

  const topBarHtml = () => {
    const balance = new BigNumber(userBalance);
    const profit = new BigNumber(floatingProfits);

    // Equity is the sum of the account balance and the floating profits
    const equityPrice = balance.plus(profit);
    const collectHtml = (
      <ul className="">
        <li>
          <h4 className="ng-binding"> {t("Balance")}</h4>
          <strong className="ng-binding">
            {userBalance ? userBalance : "0.00"}
          </strong>
          <strong className="transMoney ng-binding ng-scope"></strong>
        </li>
        <li>
          <h4 className="ng-binding">{t("Equity")}</h4>
          <strong className="changeRate ng-binding ng-scope green">
            {isNaN(equityPrice) ? (
              <strong className="changeRate ng-binding ng-scope green">
                {"0.00"}
              </strong>
            ) : (
              <strong className="changeRate ng-binding ng-scope green">
                {equityPrice ? formatDecimal(equityPrice, 2) : "0.00"}
              </strong>
            )}
          </strong>{" "}
        </li>
        <li>
          <h4 className="ng-binding">{t("Leverage")}</h4>
          {isNaN(userLeverage) ? (
            <strong className="ng-binding">{"1:1"}</strong>
          ) : (
            <strong className="ng-binding">
              {userLeverage ? `1:${userLeverage}` : "1:1"}
            </strong>
          )}
        </li>
        <li>
          <h4 className="ng-binding">{t("Used Margin")}</h4>
          {isNaN(margin) ? (
            <strong className="ng-binding">{"0.00"}</strong>
          ) : (
            <strong className="ng-binding">
              {margin ? formatDecimal(margin, 2) : "0.00"}
            </strong>
          )}
        </li>
        <li>
          <h4 className="ng-binding">{t("Available Margin")}</h4>
          {isNaN(freeMargin) ? (
            <strong className="ng-binding">{"0.00"}</strong>
          ) : (
            <strong className="ng-binding">
              {freeMargin ? formatDecimal(freeMargin, 2) : "0.00"}
            </strong>
          )}
        </li>
        <li>
          <h4 className="ng-binding">{t("Profit")}</h4>
          <strong
            className="ng-binding"
            style={
              floatingProfits < 0 ? { color: "#f23345" } : { color: "#089981" }
            }
          >
            {isNaN(floatingProfits) ? "0.0" : formatDecimal(floatingProfits, 2)}
          </strong>
        </li>
        <li>
          <h4 className="ng-binding">{username}</h4>
          <strong className="ng-binding">
            ${userBalance ? userBalance : "0.00"}
          </strong>
        </li>
      </ul>
    );
    return (
      <UserTicker
        translate={t}
        balance={userBalance}
        equityPrice={equityPrice}
        leverage={userLeverage}
        usedMargin={margin}
        freeMargin={freeMargin}
        profit={formatDecimal(accountPnL, 2)}
      />
    );
  };

  const handleChartOrderMargin = async (side) => {
    const orderObj = {
      orderSide: side === "BUY" ? "BUY" : "SELL",
      orderPrice: side === "BUY" ? selectedAsk : selectedBid,
      orderQuantity: quantity,
      orderPair: symbol,
      orderType: "market",
      status: "open",
      symbolSide: "equity",
    };
    const res = await createOrderOneClick(orderObj);
    if (res.data.success) {
      toast.success(t(res.data.message));
      setRefresh((prev) => !prev);
    } else {
      toast.error(t(res.data.message));
    }
  };

  const handlePairClick = (pairName, spread) => {
    navigate(`/forex-equity/${pairName.symbol}`);
    setCurrentPrice(pairName.best_bid);
    setOrderMethod("market");
    setIsStopLoss(false);
    setIsTakeProfit(false);
    const symbolData = {
      symbol: pairName.symbol,
      symbolType: "forex",
    };
    handleSocketMessages(localSocket, pairName.symbol);
    localSocket.current.emit("getCommissionBySymbol", symbolData);
    localSocket.current.emit("unsubscribe", { symbol, type: "eqFx" });
    localSocket.current.emit("subscribe", {
      symbol: pairName.symbol,
      type: "eqFx",
    });
    setIsTakeProfit(false);
  };

  const moveItem = useCallback(
    (dragIndex, hoverIndex) => {
      const draggedItem = coinPairData[dragIndex];
      const newData = update(coinPairData, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, draggedItem],
        ],
      });
      setCoinPairData(newData);
      const onlyIds = newData.map((item) => item.id);
      localStorage.setItem("fso", JSON.stringify(onlyIds));
    },
    [coinPairData]
  );

  const handleVisibilityChange = useCallback((id, isVisible) => {
    if (!id) return;
    // console.log(id, "id****", isVisible);
    setVisibleItems((prevVisibleItems) => {
      if (isVisible) {
        // Add id if not already present
        return prevVisibleItems.includes(id)
          ? prevVisibleItems
          : [...prevVisibleItems, id];
      } else {
        // Remove id if it is no longer visible
        return prevVisibleItems.filter((visibleId) => visibleId !== id);
      }
    });

    setInvisibleItems((prevInvisibleItems) => {
      if (!isVisible) {
        // Add id to invisibleItems if not already present
        return prevInvisibleItems.includes(id)
          ? prevInvisibleItems
          : [...prevInvisibleItems, id];
      } else {
        // Remove id from invisibleItems if it becomes visible
        return prevInvisibleItems.filter((invisibleId) => invisibleId !== id);
      }
    });
  }, []);

  useEffect(() => {
    let debounceTimeout;

    debounceTimeout = setTimeout(() => {
      // console.log(visibleItems, "visibleItems", invisibleItems);

      localSocket.current.emit("subscribeIndEquity", {
        subscribe: visibleItems,
        unSubscribe: invisibleItems || [],
      });
    }, 200);

    return () => clearTimeout(debounceTimeout);
  }, [visibleItems, invisibleItems]);

  const handleWatchListRemove = async (pairId) => {
    console.log(pairId, "paird");

    const res = await addRemove({
      symbolId: pairId,
      action: "remove",
      segment: "forex",
    });

    if (res.data.status) {
      toast.success(res.data.message);
    } else {
      toast.error(res.data.message);
    }
  };

  const assetsListHtml = () => {
    // console.log(coinPairData, "coinPairData");
    // console.log(visibleItems, "visibleItems");
    const filteredItems = coinPairData
      .filter(
        (item) =>
          (item.symbol && item.symbol.includes(searchedValue.toUpperCase())) ||
          (item.company_name &&
            item.company_name
              .toUpperCase()
              .includes(searchedValue.toUpperCase()))
      )
      .filter((item) => {
        const country = symbolCountryMap[item.symbol_key];
        return country === selectedFilter;
      });
    return (
      <tbody>
        {filteredItems.map((pair, index) => {
          const bestAsk = new Decimal(pair.best_ask);
          const bestBid = new Decimal(pair.best_bid);
          const spread = bestAsk.minus(bestBid);
          const showActive = symbol === pair.symbol;

          const htmlPart = (
            <>
              <td className="r-market-pair ng-binding fw600">
                {" "}
                {pair.icon && (
                  <img
                    src={imageUrl + "/static/currencyImage/" + pair.icon}
                    alt="icon"
                    className="currency_icon"
                  />
                )}{" "}
                <span className="mr-4">{pair.symbol}</span>
                <small className="c_name">{pair.company_name}</small>
              </td>
              <td className="r-market-price ng-binding fw600 ml-6">
                {spread.toString()}
              </td>
              <td className="r-market-rate text-right">
                <span className="ng-binding new_green">
                  {pair.best_ask}
                  <br /> {pair.best_bid}
                </span>
              </td>
              <td className="r-market-rate text-right">
                {!defaultPairs.includes(pair.symbol) ? (
                  <i
                    class="bi bi-trash"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleWatchListRemove(pair.id)}
                  ></i>
                ) : (
                  "-"
                )}
              </td>
            </>
          );

          return (
            <>
              <DraggableItem
                key={pair.id}
                index={index}
                id={pair.id}
                text={htmlPart}
                moveItem={moveItem}
                active={showActive}
                exchangeInstrumentId={pair.exchange_instrument_id}
                onClick={() => handlePairClick(pair, spread)}
                onVisibilityChange={handleVisibilityChange}
              />
              {/* <tr
                key={index}
                className={showActive ? "ng-scope active" : "ng-scope"}
                style={{ cursor: "pointer" }}
                onClick={() => handlePairClick(pair)}
              >
                <td className="r-market-pair ng-binding fw600">
                  {pair.symbol}
                </td>
                <td className="r-market-price ng-binding fw600">
                  {spread.toString()}
                </td>
                <td className="r-market-rate text-right">
                  <span
                    className="ng-binding green"
                    style={{ color: "rgb(0, 188, 139)" }}
                  >
                    {pair.best_ask}
                    <br /> {pair.best_bid}
                  </span>
                </td>
              </tr> */}
              {/* {selectedPair === pair.symbol && (
                <tr className="ng-scope active">
                  <td colspan="3" className="p14">
                    <div className="">
                      <div className="d-flex  ">
                        <button
                          className="buy_btn btn my_color_sell w100"
                          data-dir={2}
                          data-testid="button-sell"
                          onClick={(e) => handleBuyOrder(e, "SELL", pair)}
                        >
                          Sell Market
                        </button>
                        <div className="input_btn d-flex">
                          <button
                            type="button"
                            className=""
                            disabled=""
                            onClick={handleDecrement}
                            style={
                              assetsQuantity > 1.0
                                ? { cursor: "pointer" }
                                : { cursor: "default" }
                            }
                          >
                            <i className="bi bi-dash"></i>
                          </button>
                          <input
                            className="mp_input"
                            type="text"
                            value={assetsQuantity}
                            onChange={(e) => handleAssetsChange(e)}
                          />
                          <button
                            type="button"
                            className=""
                            onClick={handleIncrement}
                            style={
                              assetsQuantity < 10000
                                ? { cursor: "pointer" }
                                : { cursor: "default" }
                            }
                          >
                            <i className="bi bi-plus"></i>
                          </button>
                        </div>
                        <button
                          className="btn sell_btn w100"
                          onClick={(e) => handleBuyOrder(e, "BUY", pair)}
                        >
                          Buy Market
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              )} */}
            </>
          );
        })}
      </tbody>
    );
  };

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
    setSearchedValue("");
  };

  const handleBuyOrder = async (e, orderSide, pairData) => {
    e.preventDefault();
    const orderObj = {
      orderSide: sideAcvive === "buy" ? "BUY" : "SELL",
      orderPrice: sideAcvive === "buy" ? selectedAsk : selectedBid,
      orderQuantity: quantity,
      orderPair: symbol,
      orderType: "market",
      status: "open",
      symbolSide: "equity",
    };
    const res = await createOrderOneClick(orderObj);
    if (res.data.success) {
      toast.success(t(res.data.message));
      setRefresh((prev) => !prev);
    } else {
      toast.error(t(res.data.message));
    }
  };

  const handleCloseOrder = async (orderInfo, flotingProfit, closePrice) => {
    // console.log(positionClosing, "positionClosing");
    if (positionClosing) return;
    setCurrentClosingOrder(orderInfo);
    const orderObj = {
      orderId: orderInfo.orderIds,
      orderPair: orderInfo.symbol,
      quantity: orderInfo.totalQuantity,
      flotingProfit: flotingProfit ? flotingProfit : "0.0",
      closePrice: closePrice,
      symbolType: "equity",
    };
    const res = await closeOrderFx(orderObj);
    if (res.data.success) {
      toast.success(t(res.data.message));
      setRefresh((prev) => !prev);
      setCurrentClosingOrder(null);
    } else {
      toast.error(t(res.data.message));
      setCurrentClosingOrder(null);
    }
  };

  const handleIncrementPending = () => {
    if (quantity < 10000) {
      setQuantity((prevPrice) =>
        parseFloat(Math.min(10000, prevPrice + 1.0).toFixed(2))
      );
    }
  };

  const handleDecrementPending = () => {
    if (quantity > 1.0) {
      setQuantity((prevPrice) =>
        parseFloat(Math.max(1.0, prevPrice - 1.0).toFixed(2))
      );
    }
  };

  // const handleAssetsChangePending = (e) => {
  //   const value = e.target.value;
  //   // Allow the user to clear the input or set it to zero
  //   if (value === "" || value === "0") {
  //     setQuantity("");
  //     return;
  //   }
  //   // Allow user to type floating-point numbers freely
  //   if (/^\d*\.?\d{0,2}$/.test(value)) {
  //     const numericValue = parseFloat(value);
  //     if (numericValue <= 10000) {
  //       setQuantity(numericValue);
  //     }
  //   }
  // };

  const handleAssetsChangePending = (e) => {
    const value = e.target.value;

    if (value === "" || value === "0") {
      setQuantity("");
      return;
    }

    if (/^\d*\.?\d{0,2}$/.test(value)) {
      const numericValue = parseFloat(value);

      if (numericValue <= 10000) {
        // Round down the quantity to the nearest whole number
        const roundedQuantity = Math.floor(numericValue);
        setQuantity(roundedQuantity);

        const isBuy = sideAcvive === "buy";
        const commission = isBuy
          ? parseFloat(buyCommission || 0)
          : parseFloat(sellCommission || 0);
        const freeBalance = userBalance - (parseFloat(margin) + commission);
        const userBalanceMain = new BigNumber(freeBalance).times(
          userLeverage || 1
        );
        const price = isBuy ? selectedBid : selectedAsk;

        if (!price) {
          setMonetary("");
          return;
        }

        const totalAmount = new BigNumber(roundedQuantity).times(
          new BigNumber(price)
        );
        let netTotalAmount = new BigNumber(0);
        let fee = new BigNumber(0);

        if (isBuy) {
          if (feesType === "Flat") {
            fee = new BigNumber(commission);
            netTotalAmount = totalAmount.plus(fee);
          } else if (feesType === "Percentage") {
            fee = totalAmount.times(commission).div(100);
            netTotalAmount = totalAmount.plus(fee);
          }

          setFeeAmyt(netTotalAmount.toFixed(2));
          setMonetary(totalAmount.toFixed(2));
        } else {
          if (feesType === "Flat") {
            fee = new BigNumber(commission);
            netTotalAmount = totalAmount.plus(fee);
          } else if (feesType === "Percentage") {
            fee = totalAmount.times(commission).div(100);
            netTotalAmount = totalAmount.plus(fee);
          }

          setFeeAmyt(netTotalAmount.toFixed(2));
          setMonetary(totalAmount.toFixed(2));
        }
      }
    }
  };

  const activeBuyOrderHtml = () => {
    const bids = marketDepth
      .filter((item) => item.MDEntryType === "Bid")
      .slice(0, 4); // Display only the first 3 items
    const maxQuantity = Math.max(...bids.map((bid) => bid.MDEntrySize));

    return (
      <tbody>
        {bids.length > 0 ? (
          bids.map((bid, index) => {
            const widthPercent = (bid.MDEntrySize / maxQuantity) * 100;
            const depthQty = parseFloat(bid.MDEntrySize / 100000);
            return (
              <tr key={index} className="tr_prograss_depth">
                <td className="f-left ng-binding green crypt-up">
                  <span
                    className="tr_prograss2"
                    style={{ width: `${widthPercent}%` }}
                  ></span>
                  {bid.MDEntryPx.toFixed(5)}
                </td>
                <td>{depthQty.toLocaleString()}</td>
                <td className="text-right">
                  {(bid.MDEntryPx * depthQty).toFixed(2)}
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={3} style={{ textAlign: "center" }}>
              {t("No Data Found")}
            </td>
          </tr>
        )}
      </tbody>
    );
  };

  // Display only the first 3 sell orders (offers)
  const activeSellOrderHtml = () => {
    const offers = marketDepth
      .filter((item) => item.MDEntryType === "Offer")
      .slice(0, 4)
      .reverse(); // Reverse the array      ; // Display only the first 3 items

    const maxQuantity = Math.max(...offers.map((offer) => offer.MDEntrySize));
    return (
      <tbody>
        {offers.length > 0 ? (
          offers.map((offer, index) => {
            const widthPercent = (offer.MDEntrySize / maxQuantity) * 100;
            const depthQty = parseFloat(offer.MDEntrySize / 100000);

            return (
              <tr key={index} className="tr_prograss_depth">
                <td className="f-left ng-binding magenta crypt-down">
                  <span
                    className="tr_prograss"
                    style={{ width: `${widthPercent}%` }}
                  ></span>
                  {offer.MDEntryPx.toFixed(5)}
                </td>
                <td>{depthQty.toLocaleString()}</td>
                <td className="text-right">
                  {(offer.MDEntryPx * depthQty).toFixed(2)}
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={3} style={{ textAlign: "center" }}>
              {t("No Data Found")}
            </td>
          </tr>
        )}
      </tbody>
    );
  };

  // indian market depth
  const activeSellOrderHtmlInd = () => {
    const offers = marketDepth.Asks.reverse();

    const maxQuantity = Math.max(...offers.map((offer) => offer.Size));
    return (
      <tbody>
        {offers.length > 0 ? (
          offers.map((offer, index) => {
            const widthPercent = (offer.Size / maxQuantity) * 100;
            const depthQty = parseFloat(offer.Size);

            return (
              <tr key={index} className="tr_prograss_depth">
                <td className="f-left ng-binding magenta crypt-down">
                  <span
                    className="tr_prograss"
                    style={{ width: `${widthPercent}%` }}
                  ></span>
                  {offer.Price.toFixed(5)}
                </td>
                <td>{depthQty.toLocaleString()}</td>
                <td className="text-right">
                  {(offer.Price * depthQty).toFixed(2)}
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={3} style={{ textAlign: "center" }}>
              {t("No Data Found")}
            </td>
          </tr>
        )}
      </tbody>
    );
  };

  const activeBuyOrderHtmlInd = () => {
    const offers = marketDepth.Bids;

    const maxQuantity = Math.max(...offers.map((offer) => offer.Size));
    return (
      <tbody>
        {offers.length > 0 ? (
          offers.map((offer, index) => {
            const widthPercent = (offer.Size / maxQuantity) * 100;
            const depthQty = parseFloat(offer.Size);

            return (
              <tr key={index} className="tr_prograss_depth">
                <td className="f-left ng-binding green crypt-up">
                  <span
                    className="tr_prograss2"
                    style={{ width: `${widthPercent}%` }}
                  ></span>
                  {offer.Price}
                </td>
                <td>{depthQty.toLocaleString()}</td>
                <td className="text-right">
                  {(offer.Price * depthQty).toFixed(2)}
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={3} style={{ textAlign: "center" }}>
              {t("No Data Found")}
            </td>
          </tr>
        )}
      </tbody>
    );
  };

  const sideLastHtml = () => {
    var collectHtml = (
      <ul>
        <li>
          <strong className="ng-binding">{symbol}</strong>
          <strong className="transMoney ng-binding ng-scope"></strong>
        </li>
      </ul>
    );
    return <ul>{collectHtml}</ul>;
  };

  const handleSideChange = (side) => {
    setErrors(defaultError);
    setIsTakeProfit(false);
    // setIsStopLoss(false);
    setSideAcvive(side);
    if (orderMethod === "stop-loss") {
      setIsStopLoss(true);
      setIsTakeProfit(false);
      const currPrice = side === "buy" ? selectedBid : selectedAsk;
      const priceBN = new BigNumber(currPrice);
      const changePrice = priceBN.div(100);
      let addedPrice;

      if (side === "buy") {
        addedPrice = priceBN.plus(changePrice);
        const twoPercent = addedPrice.multipliedBy(0.02);
        addedPrice = addedPrice.minus(twoPercent);
      } else {
        addedPrice = priceBN.minus(changePrice);
        const twoPercent = addedPrice.multipliedBy(0.02);
        addedPrice = addedPrice.plus(twoPercent);
      }
      const formattedTakeProfit = formatTakeProfit(price, addedPrice);
      setStopPrice(isNaN(addedPrice.toNumber()) ? 0 : formattedTakeProfit);
    }
    setPrice(side === "buy" ? selectedAsk : selectedBid);
  };

  const formatNumberIso = (number) => {
    return number?.toNumber();
  };

  const handleRightClick = (event, data) => {
    event.preventDefault();
    setCurrentRightClickData(data);
    setMenuPosition({ x: event.clientX - 150, y: event.clientY - 120 });
    setShowMenu(true);
  };
  const handleAction = (action) => {
    if (action === "close") {
      handleCloseOrder(
        currentRightClickData.item,
        currentRightClickData.floatingProfit,
        currentRightClickData.currentPricea
      );
    }

    if (action === "modify") {
      const rowData = currentRightClickData.item;
      setSideAcvive(rowData.orderSides.toLowerCase());
      setPrice(rowData.avgPrice);
      setStopPrice(rowData.stop_price);
      setTakeProfit(rowData.take_profit);
      setSelectedRowData(rowData);
      setShow(true);
      handleSocketMessages(localSocket, rowData.symbol);
    }

    setShowMenu(false);
  };

  // useEffect(() => {
  //   let totalFloatingProfit = new BigNumber(0);

  //   openOrders.forEach((item) => {
  //     const currentPricea = item.orderSides.includes("BUY")
  //       ? new BigNumber(item.buyPrice)
  //       : new BigNumber(item.sellPrice);
  //     const orderPrice = new BigNumber(item.avgPrice);
  //     let priceChange;
  //     if (item.orderSides.includes("BUY")) {
  //       priceChange = currentPricea.minus(orderPrice);
  //     } else {
  //       priceChange = orderPrice.minus(currentPricea);
  //     }

  //     const quantity = parseFloat(item.totalQuantity);
  //     const floatingProfit = priceChange?.multipliedBy(quantity);

  //     // Calculate profit after commission
  //     const commission = new BigNumber(item.avgCommission || 0); // Assuming commission is part of the item object
  //     const profitAfterCommission = floatingProfit.minus(commission);

  //     totalFloatingProfit = totalFloatingProfit.plus(profitAfterCommission);
  //   });

  //   setFloatingProfits(totalFloatingProfit);
  // }, [openOrders]);

  useEffect(() => {
    let totalFloatingProfit = new BigNumber(0);

    closedOrders.forEach((item) => {
      const currentPricea = new BigNumber(item.closed_price);
      const orderPrice = new BigNumber(item.price);
      let priceChange;
      if (item.order_side === "BUY") {
        priceChange = currentPricea.minus(orderPrice);
      } else if (item.order_side === "SELL") {
        priceChange = orderPrice.minus(currentPricea);
      }

      const qty =
        item.order_side === "SELL" ? item.buy_quantity : item.sell_quantity;
      const quantity = parseFloat(qty);

      const floatingProfit = priceChange?.multipliedBy(quantity);
      let profitAfterCommission;
      if (item.avgCommission) {
        const commission = new BigNumber(item.avgCommission || 0); // Assuming commission is part of the item object
        // console.log("commission", commission);
        profitAfterCommission = floatingProfit.minus(commission);
      } else {
        profitAfterCommission = floatingProfit;
      }

      totalFloatingProfit = totalFloatingProfit.plus(profitAfterCommission);
    });

    setFloatingProfitsClose(totalFloatingProfit);
  }, [closedOrders]);

  const openOrderTable = () => {
    if (openOrders.length > 0) {
      return openOrders.map((item, index) => {
        const key = `${item.symbol}`;
        const matchingCoin = priceMap[key];

        const best_bid = matchingCoin ? matchingCoin.best_bid : item.best_bid;
        const best_ask = matchingCoin ? matchingCoin.best_ask : item.best_ask;
        const currentPricea = matchingCoin
          ? item.orderSides.includes("BUY")
            ? new BigNumber(best_bid)
            : new BigNumber(best_ask)
          : item.orderSides.includes("BUY")
          ? new BigNumber(item.buyPrice)
          : new BigNumber(item.sellPrice);
        const orderPrice = new BigNumber(item.avgPrice);
        let priceChange;
        if (item.orderSides.includes("BUY")) {
          priceChange = currentPricea.minus(orderPrice);
        } else {
          priceChange = orderPrice.minus(currentPricea);
        }
        let floatingProfit;

        if (item.symbol_type === "forex") {
          const quantity = parseFloat(item.totalQuantity) * parseFloat(100000);
          floatingProfit = priceChange?.multipliedBy(quantity);
        } else if (item.symbol_type === "equity") {
          const quantity = parseFloat(item.totalQuantity);
          floatingProfit = priceChange?.multipliedBy(quantity);
        }

        const grossProfitLoss = floatingProfit;

        // Calculate P&L with commission deducted
        const commission = item.avgCommission
          ? new BigNumber(item.avgCommission)
          : new BigNumber(0);
        const netProfitLoss = grossProfitLoss?.minus(commission);
        return (
          <tr
            key={item.id}
            style={{ cursor: "pointer" }}
            onDoubleClick={() =>
              !item.master_order_id ? handleDoubleClick(item) : null
            }
            onContextMenu={(e) =>
              !item.master_order_id
                ? handleRightClick(e, {
                    item,
                    floatingProfit: formatNumberIso(floatingProfit),
                    currentPricea: formatNumberIso(currentPricea),
                  })
                : null
            }
            disabled={positionClosing}
          >
            <td>{item.symbol}</td>
            <td>
              <span
                className="ng-binding ng-scope"
                style={
                  item.orderSides.includes("BUY")
                    ? { color: "rgb(0, 188, 139)" }
                    : { color: "#eb6a61" }
                }
              >
                {item.orderSides.includes("BUY") ? "BUY" : "SELL"}
              </span>
            </td>
            <td>{item.totalQuantity}</td>
            <td>{item.avgPrice}</td>
            <td>
              {item.orderSides.includes("BUY") ? item.buyPrice : item.sellPrice}
            </td>
            <td>{item.take_profit ? item.take_profit : "-"}</td>
            <td>{item.stop_price ? item.stop_price : "-"}</td>
            <td>
              {item.avgCommission
                ? "$" + parseFloat(item.avgCommission).toFixed(2)
                : "-"}
            </td>
            <td>{moment(item.created_at).format("MMM D, YYYY h:mm:ss A")}</td>
            <td
              style={
                floatingProfit < 0 ? { color: "#f23345" } : { color: "#089981" }
              }
            >
              $
              {isNaN(floatingProfit) ? "0.0" : formatDecimal(floatingProfit, 2)}
            </td>
            <td
              style={
                netProfitLoss < 0 ? { color: "#f23345" } : { color: "#089981" }
              }
            >
              ${isNaN(netProfitLoss) ? "0.0" : formatDecimal(netProfitLoss, 5)}
            </td>
            {!item.master_order_id ? (
              <td>
                {positionClosing &&
                currentOrderClosing.orderIds === item.orderIds ? (
                  <span
                    className="loading-spin"
                    style={{ color: "rgb(0, 188, 139)", cursor: "pointer" }}
                  >
                    <div className="loader"></div>
                  </span>
                ) : (
                  <span
                    style={{ color: "rgb(0, 188, 139)", cursor: "pointer" }}
                    disabled={positionClosing}
                    onClick={() =>
                      handleCloseOrder(
                        item,
                        formatNumberIso(floatingProfit),
                        formatNumberIso(currentPricea)
                      )
                    }
                  >
                    <i className="bi bi-x-circle"></i>
                  </span>
                )}
              </td>
            ) : (
              <td>-</td>
            )}
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan={12}>
            {t("You have no open orders")}
          </td>
        </tr>
      );
    }
  };

  const allOrdersTable = () => {
    if (allOrdersData.length > 0) {
      return allOrdersData.map((item, index) => {
        const key = `${item.symbol}`;
        const matchingCoin = priceMap[key]; // Lookup prices in O(1) time

        const best_bid = matchingCoin ? matchingCoin.best_bid : item.best_bid;
        const best_ask = matchingCoin ? matchingCoin.best_ask : item.best_ask;
        let qty;
        if (item.status === "close" && item.buyQty === item.sellQty) {
          qty = item.buyQty;
        } else {
          if (item.order_side === "SELL") {
            qty = `${item.buyQty}/${item.sellQty}`;
          } else {
            qty = `${item.sellQty}/${item.buyQty}`;
          }
        }
        let allStatus;
        let colour;
        if (item.status === "close") {
          allStatus = "Filled";
          colour = "#01d59b";
        } else if (item.status === "pending") {
          allStatus = "Working";
          colour = "#2962ff";
        } else if (item.status === "cancled") {
          allStatus = "Cancled";
          colour = "#ff9800";
        }
        return (
          <tr key={index}>
            <td>{item.symbol}</td>
            <td>
              <span
                className="ng-binding ng-scope "
                style={
                  item.order_side === "BUY"
                    ? { color: "rgb(0, 188, 139)" }
                    : { color: "#eb6a61" }
                }
              >
                {item.order_side}
              </span>
            </td>
            <td> {item.order_type}</td>
            <td> {item.status === "close" ? qty : item.quantity}</td>
            <td>{item.price}</td>
            <td> {item.order_side === "BUY" ? best_ask : best_bid} </td>
            <td>{item.take_profit ? item.take_profit : "-"}</td>
            <td>{item.stop_price ? item.stop_price : "-"}</td>
            <td style={{ color: colour }}>{item.status ? allStatus : "-"}</td>
            <td>{item.id ? item.id : "-"}</td>
            {item.status === "pending" && (
              <td>
                <span
                  style={{ color: "rgb(0, 188, 139)", cursor: "pointer" }}
                  onClick={() => handleCancleOrder(item)}
                >
                  <i className="bi bi-x-circle"></i>
                </span>
              </td>
            )}
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan={10}>
            {t("There is no trading data yet")}
          </td>
        </tr>
      );
    }
  };

  const pendingOrderTable = () => {
    if (pendingOrders.length > 0) {
      return pendingOrders.map((item, index) => {
        return (
          <tr key={index}>
            <td>{item.symbol}</td>
            <td>
              <span
                className="ng-binding ng-scope "
                style={
                  item.order_side === "BUY"
                    ? { color: "rgb(0, 188, 139)" }
                    : { color: "#eb6a61" }
                }
              >
                {item.order_side}
              </span>
            </td>
            <td>{item.quantity}</td>
            <td>
              {" "}
              {item.order_side === "BUY" ? item.sellPrice : item.buyPrice}{" "}
              <i className="bi bi-arrow-right"></i> {item.price}
            </td>
            <td>{item.take_profit ? item.take_profit : "-"}</td>
            <td>{item.stop_price ? item.stop_price : "-"}</td>
            <td>
              <span
                style={{ color: "rgb(0, 188, 139)", cursor: "pointer" }}
                onClick={() => handleCancleOrder(item)}
              >
                <i className="bi bi-x-circle"></i>
              </span>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan={6}>
            {t("You have no pending orders")}
          </td>
        </tr>
      );
    }
  };

  const closeOrderTable = () => {
    if (closedOrders.length > 0) {
      return closedOrders.map((item, index) => {
        const currentPricea = new BigNumber(item.closed_price);
        const orderPrice = new BigNumber(item.price);
        let priceChange = new BigNumber(0);
        if (item.order_side === "BUY") {
          priceChange = currentPricea.minus(orderPrice);
        } else if (item.order_side === "SELL") {
          priceChange = orderPrice.minus(currentPricea);
        }
        let floatingProfit = new BigNumber(0);

        if (item.symbol_type === "forex") {
          const quantity = parseFloat(item.quantity) * parseFloat(100000);
          floatingProfit = priceChange?.multipliedBy(quantity);
        } else if (item.symbol_type === "equity") {
          const qty =
            item.order_side === "SELL" ? item.buy_quantity : item.sell_quantity;
          const quantity = parseFloat(qty);

          floatingProfit = priceChange?.multipliedBy(quantity);
        }

        const grossProfitLoss = floatingProfit;

        // Calculate P&L with commission deducted
        const commission = item.avgCommission
          ? new BigNumber(item.avgCommission)
          : new BigNumber(0);
        const netProfitLoss = grossProfitLoss?.minus(commission);
        return (
          <tr key={index}>
            <td>{item.symbol}</td>
            <td>
              <span
                className="ng-binding ng-scope "
                style={
                  item.order_side === "BUY"
                    ? { color: "rgb(0, 188, 139)" }
                    : { color: "#eb6a61" }
                }
              >
                {item.order_side}
              </span>
            </td>
            <td>
              {item.status === "open"
                ? item.order_side === "SELL"
                  ? `${item.buy_quantity}/${item.sell_quantity}`
                  : `${item.sell_quantity}/${item.buy_quantity}`
                : item.order_side === "SELL"
                ? item.sell_quantity
                : item.buy_quantity}
            </td>
            <td>{moment(item.created_at).format("MMM D, YYYY h:mm:ss A")}</td>
            <td>{moment(item.updated_at).format("MMM D, YYYY h:mm:ss A")}</td>
            <td>
              {item.price} <i className="bi bi-arrow-right"></i>{" "}
              {item.closed_price}
            </td>
            <td>
              {item.trigger_by === "stop_loss"
                ? "Stop Loss"
                : item.trigger_by === "take_profit"
                ? "Take Profit"
                : "-"}
            </td>
            <td>
              {item.avgCommission
                ? "$" + parseFloat(item.avgCommission).toFixed(2)
                : "-"}
            </td>
            <td
              style={
                floatingProfit < 0 ? { color: "#f23345" } : { color: "#089981" }
              }
            >
              $
              {isNaN(floatingProfit) ? "0.0" : formatDecimal(floatingProfit, 2)}
            </td>
            <td
              style={
                floatingProfit < 0 ? { color: "#f23345" } : { color: "#089981" }
              }
            >
              ${isNaN(netProfitLoss) ? "0.0" : formatDecimal(netProfitLoss, 5)}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan={9}>
            {t("You have no close orders")}
          </td>
        </tr>
      );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let errorMessages = { ...errors };

    // Allow empty value to clear the field
    if (value === "") {
      if (name === "price") {
        setPrice(value);
        errorMessages.price = "This field is required";
      }
      if (name === "stopPrice") {
        setStopPrice(value);
        errorMessages.stopPrice = "This field is required";
      }
      if (name === "takeProfit") {
        setTakeProfit(value);
        errorMessages.takeProfit = "This field is required";
      }
      setErrors(errorMessages);
      return;
    }

    const regex = /^\d{1,5}(\.\d{0,2})?$/;
    const isValid = regex.test(value);
    if (!isValid) {
      return;
    }

    if (name === "price") {
      setPrice(value);

      if (!value) {
        errorMessages.price = "This field is required";
      } else if (value <= 0) {
        errorMessages.price = "Price should be greater than zero";
      } else {
        errorMessages.price = "";
      }
    }
    if (name === "stopPrice") {
      setStopPrice(value);
      if (sideAcvive === "buy") {
        if (value >= selectedBid) {
          errorMessages.stopPrice =
            "Set stop loss lower than the current level";
        } else {
          errorMessages.stopPrice = "";
        }
      } else if (sideAcvive === "sell") {
        if (value !== "" && Number(value) <= Number(selectedAsk)) {
          errorMessages.stopPrice =
            "Set stop loss higher than the current level";
        } else {
          errorMessages.stopPrice = "";
        }
      }
    }
    if (name === "takeProfit") {
      setTakeProfit(value);
      if (sideAcvive === "buy") {
        if (value !== "" && Number(value) && value <= selectedAsk) {
          errorMessages.takeProfit =
            "Set take profit higher than the current level";
        } else {
          errorMessages.takeProfit = "";
        }
      } else if (sideAcvive === "sell") {
        if (Number(value) >= Number(selectedBid)) {
          errorMessages.takeProfit =
            "Set take profit lower than the current level";
        } else {
          errorMessages.takeProfit = "";
        }
      }
    }

    setErrors(errorMessages);
  };

  const validateOrder = () => {
    let isValid = true;

    if (isStopLoss) {
      if (sideAcvive === "buy") {
        if (stopPrice >= selectedBid) {
          toast.error(t("Set stop loss lower than the current level"));
          isValid = false;
        }
      } else if (sideAcvive === "sell") {
        if (Number(stopPrice) <= Number(selectedAsk)) {
          toast.error(t("Set stop loss higher than the current level"));
          isValid = false;
        }
      }
    }

    if (isTakeProfit) {
      if (sideAcvive === "buy") {
        if (takeProfit <= selectedAsk) {
          toast.error(t("Set take profit higher than the current level"));
          isValid = false;
        }
      } else if (sideAcvive === "sell") {
        if (Number(takeProfit) >= Number(selectedBid)) {
          toast.error(t("Set take profit lower than the current level"));
          isValid = false;
        }
      }
    }

    return isValid;
  };

  const modifyOrder = async () => {
    let isValid = true;
    if (stopPrice) {
      if (sideAcvive === "buy") {
        if (stopPrice >= selectedBid) {
          toast.error(t("Set stop loss lower than the current level"));
          isValid = false;
        }
      } else if (sideAcvive === "sell") {
        if (Number(stopPrice) <= Number(selectedAsk)) {
          toast.error(t("Set stop loss higher than the current level"));
          isValid = false;
        }
      }
    }

    if (takeProfit) {
      if (sideAcvive === "buy") {
        if (takeProfit <= selectedAsk) {
          toast.error(t("Set take profit higher than the current level"));
          isValid = false;
        }
      } else if (sideAcvive === "sell") {
        if (Number(takeProfit) >= Number(selectedBid)) {
          toast.error(t("Set take profit lower than the current level"));
          isValid = false;
        }
      }
    }

    if (!isValid) return;
    const orderObj = {
      orderId: selectedRowData.orderIds,
      orderSide: sideAcvive === "buy" ? "BUY" : "SELL",
      stopPrice: stopPrice ? stopPrice : null,
      takeProfit: takeProfit ? takeProfit : null,
    };
    const resp = await modifyOrderClick(orderObj);
    if (resp.data.success) {
      toast.success(t(resp.data.message));
      handleClose();
      setErrors(defaultError);
      setStopPrice("");
    } else {
      toast.error(t(resp.data.message));
    }
  };

  const createPendingOrder = async (e) => {
    e.preventDefault();
    if (!validateOrder()) {
      return;
    }

    let errorMessages = { ...errors };

    if (!price) {
      errorMessages.price = "This field is required";
      setErrors(errorMessages);
      return false;
    }

    if (price <= 0) {
      errorMessages.price = "Price should be greater than zero";
      setErrors(errorMessages);
      return false;
    }

    const orderObj = {
      orderSide: sideAcvive === "buy" ? "BUY" : "SELL",
      orderPrice: price,
      orderQuantity: quantity,
      orderPair: symbol,
      orderType: isStopLoss === false ? "limit" : "stop-loss",
      status: "pending",
      stopPrice: isStopLoss ? stopPrice : "",
      takeProfit: isTakeProfit ? takeProfit : "",
      symbolSide: "equity",
    };
    const resp = await createOrderOneClick(orderObj);
    if (resp.data.success) {
      toast.success(t(resp.data.message));
      // setPrice("");
      setQuantity(1.0);
      setIsOrderModelOpen(false);
      // setIsStopLoss(false);
      setIsTakeProfit(false);
    } else {
      toast.error(t(resp.data.message));
    }
  };

  const handleCancleOrder = async (order) => {
    const orderObj = {
      orderId: order.id,
    };
    const resp = await cancleOrderFx(orderObj);
    if (resp.data.success) {
      toast.success(t(resp.data.message));
    } else {
      toast.error(t(resp.data.message));
    }
  };

  const handleStopCHange = () => {
    setIsStopLoss(!isStopLoss);
    const priceBN = new BigNumber(price);
    const changePrice = priceBN.div(100);
    let addedPrice;

    if (sideAcvive === "buy") {
      addedPrice = priceBN.plus(changePrice);
      const twoPercent = addedPrice.multipliedBy(0.02);
      addedPrice = addedPrice.minus(twoPercent);
    } else {
      addedPrice = priceBN.minus(changePrice);
      const twoPercent = addedPrice.multipliedBy(0.02);
      addedPrice = addedPrice.plus(twoPercent);
    }

    setStopPrice(isNaN(addedPrice.toNumber()) ? 0 : addedPrice.toNumber());
  };

  const handleTakeProfitChange = () => {
    setIsTakeProfit(!isTakeProfit);
    const priceBN = new BigNumber(price);
    const changePrice = priceBN.div(100);
    let addedPrice;

    if (sideAcvive === "buy") {
      addedPrice = priceBN.plus(changePrice);
      const twoPercent = addedPrice.multipliedBy(0.02);
      addedPrice = addedPrice.plus(twoPercent);
    } else {
      addedPrice = priceBN.minus(changePrice);
      const twoPercent = addedPrice.multipliedBy(0.02);
      addedPrice = addedPrice.minus(twoPercent);
    }
    const formattedTakeProfit = formatTakeProfit(price, addedPrice);

    setTakeProfit(isNaN(addedPrice.toNumber()) ? 0 : formattedTakeProfit);
  };

  const handleRemove = (value) => {
    value === "stopPrice" ? setStopPrice("") : setTakeProfit("");
  };

  const handleOrderTypeCHange = (e) => {
    const value = e;
    setOrderMethod(value);
    if (value === "stop-loss") {
      setIsStopLoss(true);
      setIsTakeProfit(false);
      const currPrice = sideAcvive === "buy" ? selectedBid : selectedAsk;
      const priceBN = new BigNumber(currPrice);
      const changePrice = priceBN.div(100);
      let addedPrice;

      if (sideAcvive === "buy") {
        addedPrice = priceBN.plus(changePrice);
        const twoPercent = addedPrice.multipliedBy(0.02);
        addedPrice = addedPrice.minus(twoPercent);
      } else {
        addedPrice = priceBN.minus(changePrice);
        const twoPercent = addedPrice.multipliedBy(0.02);
        addedPrice = addedPrice.plus(twoPercent);
      }
      const formattedTakeProfit = formatTakeProfit(price, addedPrice);
      setStopPrice(isNaN(addedPrice.toNumber()) ? 0 : formattedTakeProfit);
    } else if (value === "take-profit") {
      setIsTakeProfit(true);
      setIsStopLoss(false);
      const currPrice = sideAcvive === "buy" ? selectedAsk : selectedBid;
      const priceBN = new BigNumber(currPrice);
      const changePrice = priceBN.div(100);
      let addedPrice;

      if (sideAcvive === "buy") {
        addedPrice = priceBN.plus(changePrice);
        const twoPercent = addedPrice.multipliedBy(0.02);
        addedPrice = addedPrice.plus(twoPercent);
      } else {
        addedPrice = priceBN.minus(changePrice);
        const twoPercent = addedPrice.multipliedBy(0.02);
        addedPrice = addedPrice.minus(twoPercent);
      }

      setTakeProfit(isNaN(addedPrice.toNumber()) ? 0 : addedPrice.toNumber());
    } else {
      setIsTakeProfit(false);
      setIsStopLoss(false);
    }
    // const calculateStopPrice = parseFloat(currentPrice * 1) / 100;
    // const newPriceBuy =
    //   parseFloat(currentPrice) + parseFloat(calculateStopPrice);

    // setStopPrice(newPriceBuy.toFixed(4));
  };

  var titleData = !currentPrice
    ? "0" +
      "\xa0\xa0" +
      "|" +
      "\xa0\xa0" +
      symbol +
      "\xa0\xa0" +
      "|" +
      "\xa0\xa0" +
      `${ProductName} Exchange`
    : currentPrice > 1
    ? currentPrice.toLocaleString() +
      "\xa0\xa0" +
      "|" +
      "\xa0\xa0" +
      symbol +
      "\xa0\xa0" +
      "|" +
      "\xa0\xa0" +
      `${ProductName} Exchange`
    : currentPrice +
      "\xa0\xa0" +
      "|" +
      "\xa0\xa0" +
      symbol +
      "\xa0\xa0" +
      "|" +
      "\xa0\xa0" +
      `${ProductName} Exchange`;

  const meta = {
    title: titleData.toString(),
    description: `${ProductName} `,
    canonical: `https://${ProductName} Exchange.com/high-limit-crypto-exchange`,
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "Bitcoin Exchange, Crypto Buying Selling, Crypto Platform, Cryptocurrencies App ",
      },
    },
  };

  const resetLayout = (type) => {
    if (type === "forex" || type === "forex-equity") {
      setLayout(layouts);
      setRemovedItems(notAllowedWidgets);
      saveToLocalStorage("layouts", layouts);
      saveToLocalStorage("frl", []);
    }
  };

  const changeChartTheme = (theme) => {
    if (theme === "defaultTheme" || theme === "darkTheme") {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  };

  const removeItem = (itemId) => {
    for (const key in layout) {
      if (layout.hasOwnProperty(key)) {
        layout[key] = layout[key].filter((item) => item.i !== itemId);
      }
    }

    setLayout(layout);
    saveToLocalStorage("layouts", layout);

    setRemovedItems((prevRemovedItems) => [...prevRemovedItems, itemId]);
    setTimeout(() => {
      handleLayoutChange(undefined, layout);
    }, 300);
  };

  const addItem = (newItem) => {
    const updatedLayout = { ...layout };

    let chartData = null;

    for (const key in layouts) {
      if (layouts.hasOwnProperty(key)) {
        const chartItem = layouts[key].find((item) => item.i === newItem);

        if (chartItem) {
          chartData = chartItem;
          break;
        }
      }
    }

    const chartIndex = updatedLayout.lg.findIndex((item) => item.i === newItem);

    if (chartIndex !== -1) {
      updatedLayout.lg[chartIndex] = chartData;
    } else {
      updatedLayout.lg.push(chartData);
    }

    setRemovedItems((prevRemovedItems) =>
      prevRemovedItems.filter((itemId) => itemId !== newItem)
    );

    saveToLocalStorage(
      "frl",
      removedItems.filter((itemId) => itemId !== newItem)
    );

    setLayout(updatedLayout);

    saveToLocalStorage("layouts", updatedLayout);
    setTimeout(() => {
      handleLayoutChange(undefined, updatedLayout);
    }, 300);
  };

  function checkValueExists(value) {
    return removedItems.includes(value);
  }

  const getLayoutSettings = (section, status, page) => {
    const notAllowed = notAllowedWidgets.includes(section);

    if (notAllowed) return;
    if (!status) {
      removeItem(section);
    } else {
      addItem(section);
    }
  };

  useEffect(() => {
    for (const item of notAllowedWidgets) {
      removeItem(item);
    }
  }, [notAllowedWidgets]);

  const notificationTable = () => {
    if (notificationData.length > 0) {
      return notificationData.map((item, index) => {
        return (
          <tr key={index}>
            <td>{moment(item.created_at).format("MMM D, YYYY h:mm:ss A")}</td>
            <td>{item.title}</td>
            <td>{item.message}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan={3}>
            {t("You don't have any notifications!")}
          </td>
        </tr>
      );
    }
  };

  const cancleOrderTable = () => {
    if (cancleOrders.length > 0) {
      return cancleOrders.map((item, index) => {
        if (item.symbol_type === "equity") {
          return (
            <tr key={index}>
              <td>{item.symbol}</td>
              <td>
                <span
                  className="ng-binding ng-scope "
                  style={
                    item.order_side === "BUY"
                      ? { color: "rgb(0, 188, 139)" }
                      : { color: "#eb6a61" }
                  }
                >
                  {item.order_side}
                </span>
              </td>
              <td>{item.quantity}</td>
              <td> {item.price}</td>
              <td>{item.take_profit ? item.take_profit : "-"}</td>
              <td>{item.stop_price ? item.stop_price : "-"}</td>
            </tr>
          );
        }
      });
    } else {
      return (
        <tr>
          <td className="text-center" colSpan={6}>
            {t("You have no pending orders")}
          </td>
        </tr>
      );
    }
  };

  // const handleBuyPercentMargin = () => {};
  const handleBuyPercentMargin = (percentAmt) => {
    // console.log(sideAcvive, "sideAcvive");

    const isBuy = sideAcvive === "buy";
    const commission = isBuy
      ? parseFloat(buyCommission || 0)
      : parseFloat(sellCommission || 0);

    // Calculate the fee based on the feesType and deduct from the user's free balance
    let fee;
    if (feesType === "Flat") {
      fee = new BigNumber(commission);
    } else if (feesType === "Percentage") {
      const totalBalance = new BigNumber(userBalance)
        .times(percentAmt)
        .div(100);
      fee = totalBalance.times(commission).div(100);
    }

    const freeBalance = new BigNumber(userBalance)
      .minus(new BigNumber(margin))
      .minus(fee);
    const userBalanceMain = freeBalance.times(userLeverage || 1);
    const price = isBuy ? selectedBid : selectedAsk;

    if (!price) {
      setMonetary("");
      return;
    }

    const percentBalanceToUse = userBalanceMain.times(percentAmt).div(100);
    const quantity = percentBalanceToUse
      .div(new BigNumber(price))
      .integerValue(BigNumber.ROUND_FLOOR); // Round down to nearest whole number
    const totalAmount = quantity.times(new BigNumber(price));

    let netTotalAmount;

    if (isBuy) {
      if (feesType === "Flat") {
        netTotalAmount = totalAmount.plus(fee);
      } else if (feesType === "Percentage") {
        netTotalAmount = totalAmount.plus(fee);
      }

      setFeeAmyt(netTotalAmount.toFixed(2));
      setMonetary(totalAmount.toFixed(2));
      setQuantity(quantity.toFixed(0)); // Ensure the quantity is an integer (no decimals)
      setPercentAmount(percentAmt);
    } else {
      if (feesType === "Flat") {
        netTotalAmount = totalAmount.plus(fee);
      } else if (feesType === "Percentage") {
        netTotalAmount = totalAmount.plus(fee);
      }

      setFeeAmyt(netTotalAmount.toFixed(2));
      setMonetary(totalAmount.toFixed(2));
      setQuantity(quantity.toFixed(0)); // Ensure the quantity is an integer (no decimals)
      setPercentAmount(percentAmt);
    }
  };

  const AuthActionMargin = () => {
    const token = localStorage.getItem("jwtToken");
    if (token) {
      let marketStatus;
      if (orderMethod === "market") {
        marketStatus = "MKT";
      } else if (orderMethod === "limit") {
        marketStatus = `@ ${price} LMT`;
      } else {
        marketStatus = `@ ${price} STP`;
      }
      return (
        <button
          className={
            sideAcvive === "buy"
              ? "btn w100  my_color_buy"
              : "btn w100  my_color_sell"
          }
          type="submit"
          disabled={isLoading || !price || !quantity}
        >
          {sideAcvive === "buy" ? (
            <div>
              <span>{t("Buy")}</span>
              <br />
              <small>
                {(orderMethod === "market" || orderMethod === "stop-loss") &&
                  quantity}{" "}
                {symbol} {marketStatus}
              </small>
            </div>
          ) : (
            <div>
              <span>{t("Sell")}</span>
              <br />
              <small>
                {(orderMethod === "market" || orderMethod === "stop-loss") &&
                  quantity}{" "}
                {symbol} {marketStatus}
              </small>
            </div>
          )}
        </button>
      );
    }
    if (token == null) {
      return (
        <div className="orderforms-inputs">
          <div className="border2 p-2 tc">
            <Link to="/register-for-bitcoin-exchange">Sign Up</Link> or{" "}
            <Link to="/login">{t("Login")}</Link>
          </div>
        </div>
      );
    }
  };

  const handleVolumeChange = (e) => {
    let value = parseFloat(e.target.value);
    console.log(value, "ss", selectedRowData);

    setVolumeToClose(value);

    if (value >= 0.01 && value <= Number(selectedRowData.totalQuantity)) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  };

  const closePartialOrder = async () => {
    const orderObj = {
      orderId: selectedRowData.orderIds,
      orderPair: selectedRowData.symbol,
      closeQuantity: volumeToClose,
    };
    console.log(orderObj, "hello", selectedRowData);
    // return;

    const res = await partialOrderClose(orderObj);
    if (res.data.success) {
      toast.success(t(res.data.message));
      setRefresh((prev) => !prev);
      handleClose();
    } else {
      toast.error(t(res.data.message));
    }
  };

  return (
    <div className="forex_fix1">
      <DocumentMeta {...meta}>
        <ExchangeNavBar
          resetLayout={resetLayout}
          changeChartTheme={changeChartTheme}
          getLayoutSettings={getLayoutSettings}
          layoutChanged={removedItems}
          refresh={refresh}
          notAllowedWidgets={notAllowedWidgets}
        />
        {layout && (
          <ResponsiveGridLayout
            className="layout dashbord_manbox exchange-main-outer fs13"
            layouts={layout}
            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
            cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
            rowHeight={30}
            margin={[1, 1]}
            onLayoutChange={handleLayoutChange}
            resizeHandles={["se", "sw", "ne", "nw"]}
            draggableHandle=".drag-handle"
            draggableCancel=".drag-cancel"
          >
            <div key="topbar">
              <div className="bg2 kline-para kline-para-basic d-flex align-items-center">
                {/* top bar */}
                <div className="box-kline-para-basic-left ">
                  <span
                    className="productSymbol has-info ng-scope"
                    style={{ fontSize: "11px" }}
                  >
                    <strong className="ng-binding">{symbol}</strong>
                  </span>
                </div>
                <div className="box-kline-para-basic-right forex_list ">
                  {topBarHtml()}
                </div>
              </div>
            </div>
            {!checkValueExists("pairslist") && (
              <div key="pairslist" className="dashbord_in dashbord_in_top fs13">
                <div className="forex_left_box padding-right  forex_left_table">
                  <div
                    className="close-resize drag-cancel"
                    onClick={() => removeItem("pairslist")}
                  >
                    <i className="bi bi-x-lg"></i>
                  </div>
                  <div className=" ne_box p-3 br10  bg2 h100">
                    <div className="row">
                      <h6 className="drag-handle text-left">{t("Assets")}</h6>
                      <i
                        className="bi bi-plus-lg cursor-pointer ml-2 drag-cancle"
                        title="Add Symbol"
                        style={{ fontSize: "16px" }}
                        onClick={() => setIsModalOpen(true)}
                      />
                    </div>
                    <div className="search-box search-box-new">
                      <input
                        type="text"
                        id="search-int"
                        placeholder="Search"
                        className="ng-pristine ng-untouched ng-valid ng-empty"
                        onChange={onSearch}
                        value={searchedValue}
                        autoComplete="off"
                      />
                      {searchedValue !== "" ? (
                        <i
                          onClick={EmptySearch}
                          className="las la-times-circle"
                        ></i>
                      ) : (
                        <i className="las la-search"></i>
                      )}
                    </div>

                    <ul className="exchange_tab mt-2 mb-2  pt-1">
                      <li
                        className={
                          selectedFilter === "USA"
                            ? "active tabQuoteAsset"
                            : "tabQuoteAsset"
                        }
                        onClick={() => handleFilterChange("USA")}
                      >
                        USA
                      </li>
                      <li
                        className={
                          selectedFilter === "Hong Kong"
                            ? "active tabQuoteAsset"
                            : "tabQuoteAsset"
                        }
                        onClick={() => handleFilterChange("Hong Kong")}
                      >
                        Hong Kong
                      </li>
                      <li
                        className={
                          selectedFilter === "India"
                            ? "active tabQuoteAsset"
                            : "tabQuoteAsset"
                        }
                        onClick={() => handleFilterChange("India")}
                      >
                        India
                      </li>
                    </ul>

                    <table className="table mb-0 currency_table1 ">
                      <thead>
                        <tr>
                          <th
                            className="f-left r-market-pair ng-binding mr-4"
                            ng-click="sortByKey('baseAsset')"
                          >
                            {t("Symbol")}
                          </th>
                          <th className="f-left r-market-price ng-binding ml-4">
                            {t("Spread")}
                          </th>
                          <th className="r-market-rate ng-binding ng-scope text-right">
                            {t("Ask/Bid")}{" "}
                          </th>
                          <th className="r-market-rate ng-binding ng-scope text-right">
                            {t("Remove")}
                          </th>
                        </tr>
                      </thead>
                    </table>

                    <div className="table-overflow-forex table-overflow-forex_e">
                      <DndProvider backend={HTML5Backend}>
                        <table className="table table-hover currency_table">
                          {assetsListHtml()}
                        </table>
                      </DndProvider>
                      <div className=" currency_table p-1 d-flex">
                        <i
                          className="bi bi-plus-lg cursor-pointer mr-2 drag-cancle"
                          title="Add Symbol"
                          style={{ fontSize: "12px" }}
                          onClick={() => setIsModalOpen(true)}
                        >
                          <span className="r-market-pair ng-binding fw600 ng-scope ml-2">
                            Click to add...
                          </span>
                        </i>
                      </div>
                    </div>

                    {/* <div
                    className={
                      isOrderModelOpen === true
                        ? "pending_order_left open bg2 br10 p-3 h100"
                        : "pending_order_left bg2 br10 p-3 h100 abc"
                    }
                  >
                    <div className="in_scrool">
                      <div className="d-flex   align-items-center">
                        <img
                          className="currency_icon"
                          src="http://192.168.1.44:8000/static/currencyImage/currency-1659701683724.png"
                          alt=""
                        />
                        {symbol}
                        <i
                          className="bi bi-x-lg ml-auto cursor-pointer"
                          onClick={() => setIsOrderModelOpen(!isOrderModelOpen)}
                        ></i>
                      </div>

                      <div className="d-flex  pending_in_tab mb-3 mt-3 bg1 br10 p-1">
                        <button
                          className={sideAcvive === "sell" ? "active" : ""}
                          onClick={() => handleSideChange("sell")}
                        >
                          {" "}
                          Sell Limit
                          <span
                            style={
                              sideAcvive === "sell"
                                ? { color: "white" }
                                : { color: "#eb6a61" }
                            }
                          >
                            {selectedAsk}
                          </span>
                        </button>
                        <button
                          className={sideAcvive === "buy" ? "active" : ""}
                          onClick={() => handleSideChange("buy")}
                        >
                          {" "}
                          Buy Limit
                          <span
                            style={
                              sideAcvive === "buy"
                                ? { color: "white" }
                                : { color: "rgb(0, 188, 139)" }
                            }
                          >
                            {selectedBid}
                          </span>
                        </button>
                      </div>
                      <h6 className="mt-3 mb-3">
                        {" "}
                        <b>Create pending</b>
                      </h6>

                      <div className="mb-3">
                        <label>Entry price</label>
                        <input
                          type="text"
                          className="form-control"
                          value={price}
                          name="price"
                          onChange={handleChange}
                        />
                        <div className="d-flex mt-1">
                          <small>Distance</small>
                          <small className="ml-auto">
                            {distance.toString()} points
                          </small>
                        </div>
                      </div>

                      <div className="mb-3">
                        <label>Order volume</label>
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            value={quantity}
                            name="quantity"
                            onChange={handleAssetsChangePending}
                          />
                          <div className="input_btn d-flex input_btn_right">
                            <button
                              type="button"
                              className="cursor-pointer"
                              onClick={handleDecrementPending}
                            >
                              <i className="bi bi-dash"></i>
                            </button>
                            <button
                              type="button"
                              className="ml-2 cursor-pointer"
                              onClick={handleIncrementPending}
                            >
                              <i className="bi bi-plus"></i>
                            </button>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <small>Monetary equivalent</small>
                          <small className="ml-auto">USD {userBalance}</small>
                        </div>
                      </div>

                      <div className="mb-3 br10 bg3 p-2">
                        <label className="d-flex align-items-center">
                          Stop Loss
                          <small
                            className="ml-auto  cursor-pointer plus_minush collapsed"
                            data-bs-toggle="collapse"
                            href="#collapseTwo"
                            onClick={handleStopCHange}
                          >
                            <i className="bi bi-plus-circle h6 "></i>
                          </small>
                        </label>
                        <div
                          id="collapseTwo"
                          className="collapse"
                          data-bs-parent="#accordion"
                        >
                          <input
                            type="text"
                            className="form-control"
                            value={stopPrice}
                            name="stopPrice"
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="mb-3 br10 bg3 p-2">
                        <label className="d-flex align-items-center">
                          Take profit
                          <small
                            className="ml-auto  cursor-pointer plus_minush collapsed"
                            data-bs-toggle="collapse"
                            href="#collapseTwo1"
                            onClick={handleTakeProfitChange}
                          >
                            <i className="bi bi-plus-circle h6 "></i>
                          </small>
                        </label>
                        <div
                          id="collapseTwo1"
                          className="collapse"
                          data-bs-parent="#accordion"
                        >
                          <input
                            type="text"
                            className="form-control"
                            value={takeProfit}
                            name="takeProfit"
                            onChange={handleChange}
                          />

                        </div>
                      </div>
                    </div>
                    <div className="place_order_fix">
                      <button
                        className="btn w100  mt-2 p-2"
                        onClick={createPendingOrder}
                      >
                        {t("Place order")}
                      </button>
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
            )}
            {!checkValueExists("buy") && (
              <div key="buy">
                <div
                  className="close-resize drag-cancel"
                  onClick={() => removeItem("buy")}
                >
                  <i className="bi bi-x-lg"></i>
                </div>
                <div className="h_100">
                  <div className="ne_box h_100 p-3 br10 bg2">
                    <div className="d-flex mb-3">
                      <h6 class="h6_head drag-handle mb-0">Order & Trading </h6>
                    </div>
                    <ul class="exchange_tab2  drag-handle br10  d-flex mb-3 position-relative">
                      <span className="bs_center">
                        {formatDecimal(selectedAsk - selectedBid, 5)}
                      </span>
                      <li
                        onClick={() => handleSideChange("sell")}
                        className={`drag-cancel ${
                          sideAcvive === "sell" ? "my_color_sell " : ""
                        }`}
                      >
                        {t("Sell")}
                        <br />
                        {!selectedAsk ? "0" : formatDecimal(selectedAsk, 5)}
                      </li>
                      <li
                        onClick={() => handleSideChange("buy")}
                        className={`drag-cancel ${
                          sideAcvive === "buy" ? "my_color_buy " : ""
                        }`}
                      >
                        {t("Buy")}
                        <br />
                        {!selectedBid ? "0" : formatDecimal(selectedBid, 5)}
                      </li>
                    </ul>
                    <div class="position_tab position_tab_center d-md-flex mb-3">
                      <a
                        href="#!"
                        class={orderMethod === "market" ? "active" : ""}
                        onClick={() => handleOrderTypeCHange("market")}
                      >
                        {" "}
                        Market
                      </a>
                      <a
                        href="#!"
                        class={orderMethod === "limit" ? "active" : ""}
                        onClick={() => handleOrderTypeCHange("limit")}
                      >
                        Limit
                      </a>
                      <a
                        href="#!"
                        class={orderMethod === "stop-loss" ? "active" : ""}
                        onClick={() => handleOrderTypeCHange("stop-loss")}
                      >
                        Stop
                      </a>
                    </div>
                    <div className="buy-btc-outer buy-sell-outer border1">
                      <form
                        name="buyForm"
                        autoComplete="off"
                        onSubmit={
                          orderMethod === "market"
                            ? handleBuyOrder
                            : createPendingOrder
                        }
                        className="ng-pristine ng-invalid ng-invalid-required"
                      >
                        <div className="orderforms-inputs">
                          <div className="field f-cb mb-2">
                            {orderMethod !== "market" && (
                              <label className="ng-binding d-flex">
                                {t("Price")}{" "}
                              </label>
                            )}
                            <div className="iptwrap leftBig">
                              {orderMethod !== "market" && (
                                <>
                                  <input
                                    type={
                                      orderMethod === "limit"
                                        ? "number"
                                        : "text"
                                    }
                                    className="ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                                    value={
                                      orderMethod === "market"
                                        ? "MARKET"
                                        : price
                                    }
                                    name="price"
                                    onChange={handleChange}
                                    disabled={
                                      orderMethod === "market" ? true : false
                                    }
                                  />

                                  <span className="text-danger mb-2">
                                    {t(errors.price)}
                                  </span>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="field f-cb mb-2">
                            <label className="ng-binding d-flex">
                              {t("Quantity")}:
                            </label>
                            <div className="iptwrap">
                              <input
                                type="text"
                                step="any"
                                value={quantity}
                                name="quantity"
                                onChange={handleAssetsChangePending}
                              />
                            </div>
                          </div>
                          <div className="row  pt-3  mb-3 align-items-end">
                            <div className="col-5">
                              <div className="form-check mb-2">
                                <input
                                  className="form-check-input check_box"
                                  type="checkbox"
                                  checked={isTakeProfit}
                                  id="flexCheckChecked"
                                />
                                <span
                                  class="checkmark"
                                  onClick={() => setIsTakeProfit(!isTakeProfit)}
                                ></span>
                                <label
                                  className="form-check-label pl-2"
                                  htmlFor="flexCheckChecked"
                                  onClick={() => setIsTakeProfit(!isTakeProfit)}
                                >
                                  Take Profit
                                </label>
                              </div>
                              <input
                                type="text"
                                value={takeProfit}
                                placeholder="0"
                                name="takeProfit"
                                disabled={!isTakeProfit}
                                onChange={handleChange}
                              />
                            </div>
                            <div
                              className="col-2 d-flex"
                              style={{ justifyContent: "center" }}
                            >
                              <label>Price</label>
                            </div>
                            <div className="col-5">
                              <div className="form-check mb-2">
                                <input
                                  className="form-check-input check_box"
                                  type="checkbox"
                                  checked={isStopLoss}
                                />
                                <span
                                  class="checkmark"
                                  onClick={() => setIsStopLoss(!isStopLoss)}
                                ></span>
                                <label
                                  className="form-check-label pl-2"
                                  for="flexCheckChecked"
                                  onClick={() => setIsStopLoss(!isStopLoss)}
                                >
                                  Stop Loss
                                </label>
                              </div>
                              <input
                                className=""
                                type="text"
                                placeholder="0"
                                value={stopPrice}
                                name="stopPrice"
                                onChange={handleChange}
                                disabled={!isStopLoss}
                              />
                            </div>
                          </div>
                          <div className="field percent f-cb">
                            <div className="d-flex field4 mb-2">
                              <span
                                className={`col ${
                                  percentAmount === 25 ? "my_color_buy" : ""
                                }`}
                                onClick={() => handleBuyPercentMargin(25)}
                              >
                                25%
                              </span>
                              <span
                                className={`col ${
                                  percentAmount === 50 ? "my_color_buy" : ""
                                }`}
                                onClick={() => handleBuyPercentMargin(50)}
                              >
                                50%
                              </span>
                              <span
                                className={`col ${
                                  percentAmount === 75 ? "my_color_buy" : ""
                                }`}
                                onClick={() => handleBuyPercentMargin(75)}
                              >
                                75%
                              </span>
                              <span
                                className={`col ${
                                  percentAmount === 100 ? "my_color_buy" : ""
                                }`}
                                onClick={() => handleBuyPercentMargin(100)}
                              >
                                100%
                              </span>
                            </div>
                          </div>
                          <div className="field f-cb mb-2">
                            <label className="ng-binding">{t("Total")}: </label>
                            <div className="iptwrap">
                              <input
                                type="text"
                                step="any"
                                id="total_buy"
                                name="buy_order_total"
                                disabled
                                className="ng-pristine ng-untouched ng-valid ng-empty"
                                value={monetary ? monetary.toString() : ""}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p style={{ fontSize: 10 }} className="ng-binding">
                              {t("Trade Fee")} :{" "}
                              {feesType === "Flat" && <span>$</span>}
                              {sideAcvive === "buy"
                                ? buyCommission
                                : sellCommission}
                              {feesType === "Percentage" && <span>%</span>}
                            </p>
                            <p style={{ fontSize: 10 }} className="ng-binding">
                              {t("Net Amount")} :{" "}
                              {feeAmyt ? formatDecimal(feeAmyt, 5) : 0}
                            </p>
                          </div>
                          <div className="orderforms-inputs">
                            {AuthActionMargin()}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                {/* <div
                  className={
                    isOrderModelOpen === true
                      ? "pending_order_left open bg2 br10 p-3 h100"
                      : "pending_order_left open bg2 br10 p-3 h100"
                  }
                >
                  <div className="in_scrool ">
                    <div className="d-flex mb-3  align-items-center">
                      <h6 className="mb-0 drag-handle">
                        <b>{t("Create Order")}</b>
                      </h6>
                      <b className="ml-auto">
                        {show ? selectedRowData.symbol : symbol}
                      </b>
                    </div>
                    <select
                      className="   br10  form-control "
                      value={orderMethod}
                      onChange={handleOrderTypeCHange}
                    >
                      <option value={""}>{t("Select")}</option>
                      <option value={"limit"}>{t("Limit")}</option>
                      <option value={"market"}>{t("Market")}</option>
                      <option value={"stop-loss"}>{t("Stop Loss")}</option>
                    </select>
                    <div className="d-flex  pending_in_tab mb-3 mt-3  br10 p-1 drag-cancel">
                      <button
                        className={sideAcvive === "sell" ? "active " : ""}
                        onClick={() => handleSideChange("sell")}
                      >
                        {" "}
                        {t("Sell")}
                        <span
                          style={
                            sideAcvive === "sell"
                              ? { color: "white" }
                              : { color: "#eb6a61" }
                          }
                        >
                          {selectedAsk}
                        </span>
                      </button>
                      <button
                        className={sideAcvive === "buy" ? "active" : ""}
                        onClick={() => handleSideChange("buy")}
                      >
                        {" "}
                        {t("Buy")}
                        <span
                          style={
                            sideAcvive === "buy"
                              ? { color: "white" }
                              : { color: "rgb(0, 188, 139)" }
                          }
                        >
                          {selectedBid}
                        </span>
                      </button>
                    </div>
                    {isStopLoss && (
                      <div className="mb-2">
                        <label>{t("Stop Loss")}</label>
                        <input
                          type="text"
                          className="form-control"
                          value={stopPrice}
                          name="stopPrice"
                          onChange={handleChange}
                        />

                        <div className="text-danger">{t(errors.stopPrice)}</div>
                      </div>
                    )}

                    <div className="mb-2">
                      <label>{t("Entry price")}</label>
                      <input
                        type={orderMethod === "limit" ? "number" : "text"}
                        className="form-control"
                        value={orderMethod === "market" ? "MARKET" : price}
                        name="price"
                        onChange={handleChange}
                        disabled={orderMethod === "market" ? true : false}
                        autoComplete="off"
                      />
                      <div className="text-danger">{t(errors.price)}</div>
                    </div>

                    <div className="mb-3">
                      <label>{t("Order volume")}</label>
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          value={quantity}
                          name="quantity"
                          onChange={handleAssetsChangePending}
                          autoComplete="off"
                        />
                        <div className="input_btn d-flex input_btn_right">
                          <button
                            type="button"
                            className="cursor-pointer"
                            onClick={handleDecrementPending}
                          >
                            <i className="bi bi-dash"></i>
                          </button>
                          <button
                            type="button"
                            className="ml-2 cursor-pointer"
                            onClick={handleIncrementPending}
                          >
                            <i className="bi bi-plus"></i>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="mb-3 br10 bg3 p-2">
                      <label className="d-flex align-items-center">
                        {t("Take Profit")}
                        <small
                          className={`ml-auto  cursor-pointer plus_minush ${
                            !isTakeProfit ? "collapsed" : ""
                          }`}
                          data-bs-toggle="collapse"
                          href="#collapseTwo1"
                          onClick={handleTakeProfitChange}
                        >
                          <i className="bi bi-plus-circle h6 "></i>
                        </small>
                      </label>
                      <div
                        id="collapseTwo1"
                        className={`collapse ${
                          !isTakeProfit ? "hide" : "show"
                        }`}
                        data-bs-parent="#accordion"
                      >
                        <input
                          type="text"
                          className="form-control"
                          value={takeProfit}
                          name="takeProfit"
                          onChange={handleChange}
                          autoComplete="off"
                        />
                        <div className="text-danger">
                          {t(errors.takeProfit)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="place_order_fix">
                    {orderMethod === "market" ? (
                      <button
                        className="btn w100  mt-2 p-2"
                        disabled={isLoading}
                        onClick={handleBuyOrder}
                      >
                        {t("Place order")}
                      </button>
                    ) : (
                      <button
                        className="btn w100  mt-2 p-2"
                        disabled={isLoading}
                        onClick={createPendingOrder}
                      >
                        {t("Place order")}
                      </button>
                    )}
                  </div>
                </div> */}
              </div>
            )}
            {!checkValueExists("chart") && (
              <div key="chart" className="drag-handle">
                <div className="ne_box br10  bg2 h387">
                  <div className="binance-chart-container">
                    <div
                      className="close-resize drag-cancel"
                      onClick={() => removeItem("chart")}
                    >
                      <i className="bi bi-x-lg"></i>
                    </div>
                    <div className="h-100 p-2 ">
                      <TradingViewForexWidgit theme={theme} symbol={symbol} />
                      <ul class="chart_buy_sell  d-flex  position-absolute align-items-center">
                        <li
                          class="drag-cancel my_color_sell"
                          onClick={() => handleChartOrderMargin("SELL")}
                        >
                          {selectedAsk}
                        </li>

                        <input
                          className="bg2"
                          type="number"
                          value={quantity}
                          name="quantity"
                          onChange={handleAssetsChangePending}
                        />

                        <li
                          class="drag-cancel my_color_buy"
                          onClick={() => handleChartOrderMargin("BUY")}
                        >
                          {selectedBid}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!checkValueExists("marketdepth") && (
              <div key="marketdepth">
                <div className=" forex_t_height">
                  <div className="ne_box  p-3 br10  bg2">
                    <h6 className="drag-handle text-left">
                      {t("Market Depth")}
                    </h6>
                    <div
                      className="close-resize drag-cancel"
                      onClick={() => removeItem("marketdepth")}
                    >
                      <i className="bi bi-x-lg"></i>
                    </div>
                    <div className=" bs_border t-height1">
                      <table className="table mb-0 market_depth_table">
                        <thead>
                          <tr>
                            <th>{t("Price")}</th>
                            <th>{t("Qty")}.</th>
                            <th className="text-right">{t("Total")}</th>
                          </tr>
                        </thead>
                        {marketDepth.exchange === "ind"
                          ? activeSellOrderHtmlInd()
                          : activeSellOrderHtml()}
                      </table>
                    </div>
                    <h6 className="text-center t2t m-0 ">{sideLastHtml()}</h6>
                    <div className="bs_border t-height1 ">
                      <table className="table mb-0 market_depth_table">
                        <thead>
                          <tr>
                            <th>{t("Price")}</th>
                            <th>{t("Qty")}.</th>
                            <th className="text-right">{t("Total")}</th>
                          </tr>
                        </thead>
                        {marketDepth.exchange === "ind"
                          ? activeBuyOrderHtmlInd()
                          : activeBuyOrderHtml()}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!checkValueExists("orderdetails") && (
              <div key="orderdetails">
                <div className="ne_box pl-3 pr-3 pb-3 pt-2 br10 h100 bg2">
                  <div className="table_hadding flex-wrap mt-3 mb-2">
                    <h6 className=" drag-handle text-left">{t("Orders")}</h6>
                    {positionAllClosing ? (
                      <button className="btn btn-close-all drag-cancel">
                        <span
                          className="loading-spin"
                          style={{
                            color: "rgb(0, 188, 139)",
                            cursor: "pointer",
                          }}
                        >
                          <div
                            className="loader"
                            style={{ display: "inline-block" }}
                          ></div>
                        </span>
                      </button>
                    ) : (
                      openOrders &&
                      openOrders.length > 0 && (
                        <button
                          disabled={positionAllClosing}
                          onClick={(e) => {
                            e.preventDefault();
                            closeAllPositions({ symbolType: "equity" });
                          }}
                          className="btn btn-close-all drag-cancel"
                        >
                          Close All Positions
                        </button>
                      )
                    )}
                  </div>
                  <div
                    className="close-resize drag-cancel"
                    onClick={() => removeItem("orderdetails")}
                  >
                    <i className="bi bi-x-lg"></i>
                  </div>
                  <div className="position_tab d-md-flex mb-3">
                    <div className=" d-flex mb-2 mb-md-0">
                      <a
                        className={
                          activeOrderTab === "positions"
                            ? "active tabQuoteAsset"
                            : "tabQuoteAsset"
                        }
                        href="#!"
                        onClick={() => setActiveOrderTab("positions")}
                      >
                        {t("Positions")}{" "}
                        {openOrders.length > 0 ? openOrders.length : ""}
                      </a>
                      <a
                        className={
                          activeOrderTab === "all" ||
                          activeOrderTab === "working" ||
                          activeOrderTab === "filled" ||
                          activeOrderTab === "cancelled"
                            ? "active tabQuoteAsset"
                            : "tabQuoteAsset"
                        }
                        href="#!"
                        onClick={() => setActiveOrderTab("all")}
                      >
                        {t("Orders")}
                      </a>
                      <a
                        className={
                          activeOrderTab === "account_summary"
                            ? "active tabQuoteAsset"
                            : "tabQuoteAsset"
                        }
                        href="#!"
                        onClick={() => setActiveOrderTab("account_summary")}
                      >
                        {t("Account Summary")}
                      </a>
                      <a
                        className={
                          activeOrderTab === "notification_summary"
                            ? "active tabQuoteAsset"
                            : "tabQuoteAsset"
                        }
                        href="#!"
                        onClick={() =>
                          setActiveOrderTab("notification_summary")
                        }
                      >
                        {t("Notification Summary")}
                      </a>
                    </div>
                    <div className="total_profit ml-auto">
                      {activeOrderTab === "positions" ? (
                        <h6 className="mb-0 mr-md-2">
                          {t("Open")} {t("P & L")}:{" "}
                          <span
                            style={
                              floatingProfits < 0
                                ? { color: "#f23345" }
                                : { color: "#00bc8b" }
                            }
                          >
                            ${formatDecimal(floatingProfits, 2)}
                          </span>
                        </h6>
                      ) : (
                        <h6 className="mb-0 mr-md-2">
                          {t("Close")} {t("P & L")}:{" "}
                          <span
                            style={
                              floatingProfitsClose < 0
                                ? { color: "#f23345" }
                                : { color: "#00bc8b" }
                            }
                          >
                            ${formatDecimal(floatingProfitsClose, 2)}
                          </span>
                        </h6>
                      )}
                    </div>
                  </div>
                  {(activeOrderTab === "all" ||
                    activeOrderTab === "working" ||
                    activeOrderTab === "filled" ||
                    activeOrderTab === "cancelled") && (
                    <div className="position_tab_in d-flex mb-2 mb-md-0">
                      <a
                        href="#!"
                        onClick={() => setActiveOrderTab("all")}
                        className={activeOrderTab === "all" ? "active" : ""}
                      >
                        {t("All")}{" "}
                        {allOrdersData.length > 0 ? allOrdersData.length : ""}
                      </a>
                      <a
                        href="#!"
                        onClick={() => setActiveOrderTab("working")}
                        className={activeOrderTab === "working" ? "active" : ""}
                      >
                        {t("Working")}{" "}
                        {pendingOrders.length > 0 ? pendingOrders.length : ""}
                      </a>
                      <a
                        href="#!"
                        onClick={() => setActiveOrderTab("filled")}
                        className={activeOrderTab === "filled" ? "active" : ""}
                      >
                        {t("Filled")}{" "}
                        {closedOrders.length > 0 ? closedOrders.length : ""}
                      </a>
                      <a
                        href="#!"
                        onClick={() => setActiveOrderTab("cancelled")}
                        className={
                          activeOrderTab === "cancelled" ? "active" : ""
                        }
                      >
                        {t("Cancelled")}{" "}
                        {cancleOrders.length > 0 ? cancleOrders.length : ""}
                      </a>
                    </div>
                  )}
                  <div className="position-relative h100">
                    <div className="forex_table_scrool">
                      {showMenu && (
                        <ContextMenu
                          x={menuPosition.x}
                          y={menuPosition.y}
                          onAction={handleAction}
                          menuRef={menuRef}
                        />
                      )}
                      {activeOrderTab === "positions" ? (
                        <table className="table">
                          <thead>
                            <tr>
                              <th>{t("Symbol")}</th>
                              <th>{t("Direction")}</th>
                              <th>{t("Quantity, shares")}</th>
                              <th>{t("Entry point")}</th>
                              <th>{t("Price change")}</th>
                              <th>{t("Take Profit")}</th>
                              <th>{t("Stop Loss")}</th>
                              <th>{t("Commission")}</th>
                              <th>{t("Open Time")}</th>
                              <th>{t("Gross Profit")}</th>
                              <th>{t("Profit")}</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>{openOrderTable()} </tbody>
                        </table>
                      ) : activeOrderTab === "all" ? (
                        <table className="table">
                          <thead>
                            <tr>
                              <th>{t("Symbol")}</th>
                              <th>{t("Direction")}</th>
                              <th>{t("Type")}</th>
                              <th>{t("Quantity, shares")}</th>
                              <th>{t("Entry point")}</th>
                              <th>{t("Price change")}</th>
                              <th>{t("Take Profit")}</th>
                              <th>{t("Stop Loss")}</th>
                              <th>{t("Status")}</th>
                              <th>{t("OrderID")}</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>{allOrdersTable()}</tbody>
                        </table>
                      ) : activeOrderTab === "working" ? (
                        <table className="table">
                          <thead>
                            <tr>
                              <th>{t("Symbol")}</th>
                              <th>{t("Direction")}</th>
                              <th>{t("Quantity, shares")}</th>
                              <th>{t("Entry Price")}</th>
                              <th>{t("Take Profit")}</th>
                              <th>{t("Stop Loss")}</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>{pendingOrderTable()}</tbody>
                        </table>
                      ) : activeOrderTab === "filled" ? (
                        <table className="table">
                          <thead>
                            <tr>
                              <th>{t("Symbol")}</th>
                              <th>{t("Direction")}</th>
                              <th>{t("Quantity, shares")}</th>
                              <th>{t("Open Time")}</th>
                              <th>{t("Close Time")}</th>
                              <th>{t("Price change")}</th>
                              <th>{t("Trigger By")}</th>
                              <th>{t("Commission")}</th>
                              <th>{t("Gross Profit")}</th>
                              <th>{t("Profit")}</th>
                            </tr>
                          </thead>
                          <tbody>{closeOrderTable()}</tbody>
                        </table>
                      ) : activeOrderTab === "cancelled" ? (
                        <table className="table">
                          <thead>
                            <tr>
                              <th>{t("Symbol")}</th>
                              <th>{t("Direction")}</th>
                              <th>{t("Quantity, lots")}</th>
                              <th>{t("Entry Price")}</th>
                              <th>{t("Take Profit")}</th>
                              <th>{t("Stop Loss")}</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>{cancleOrderTable()}</tbody>
                        </table>
                      ) : activeOrderTab === "account_summary" ? (
                        <table className="table">
                          <thead>
                            <tr>
                              <th>{t("Title")}</th>
                              <th>{t("Balance")}</th>
                              <th>{t("Open PL	")}</th>
                              <th>{t("Equity")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {" "}
                            <tr>
                              <td>User Balance</td>
                              <td>
                                {userBalance
                                  ? Number.isInteger(userBalance)
                                    ? userBalance
                                    : parseFloat(userBalance)
                                  : 0}
                              </td>
                              <td>{formatDecimal(floatingProfits, 2)}</td>
                              <td>
                                {" "}
                                {new BigNumber(userBalance ? userBalance : 0)
                                  .plus(floatingProfits)
                                  .toFixed(5)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      ) : (
                        <table className="table">
                          <thead>
                            <tr>
                              <th>{t("Time")}</th>
                              <th>{t("Title")}</th>
                              <th>{t("Message")}</th>
                            </tr>
                          </thead>
                          <tbody>{notificationTable()}</tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </ResponsiveGridLayout>
        )}

        {show && (
          <Modal show={show} onHide={handleClose} className="light-model">
            <Modal.Header>
              <Modal.Title className="h6">Order Management</Modal.Title>
              <span style={{ cursor: "pointer" }}>
                <i onClick={handleClose} className="bi bi-x-circle"></i>
              </span>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex pending_in_tab mb-3 mt-3 bg1 br10 p-1 drag-cancel">
                <button
                  className={`tab-btn ${
                    activeTab === "modify" ? "active" : ""
                  }`}
                  onClick={() => setActiveTabModify("modify")}
                >
                  Modify Order
                </button>
                <button
                  className={`tab-btn ${
                    activeTab === "partialClose" ? "active" : ""
                  }`}
                  onClick={() => setActiveTabModify("partialClose")}
                >
                  Partial Close
                </button>
              </div>
              {activeTab === "modify" && (
                <>
                  <div className="">
                    <div className="order-info  d-flex   justify-content-between">
                      Order ID:- {orderId}{" "}
                      <span>Entry Price:- ${displayPrice}</span>
                    </div>
                    <div className="d-flex  pending_in_tab mb-3 mt-3  br10 p-1 drag-cancel">
                      <button
                        className={sideAcvive === "sell" ? "active " : ""}
                        disabled
                      >
                        {" "}
                        Sell Limit
                        <span
                          style={
                            sideAcvive === "sell"
                              ? { color: "white" }
                              : { color: "#eb6a61" }
                          }
                        >
                          {selectedAsk}
                        </span>
                      </button>
                      <button
                        className={sideAcvive === "buy" ? "active" : ""}
                        disabled
                      >
                        {" "}
                        Buy Limit
                        <span
                          style={
                            sideAcvive === "buy"
                              ? { color: "white" }
                              : { color: "rgb(0, 188, 139)" }
                          }
                        >
                          {selectedBid}
                        </span>
                      </button>
                    </div>
                    {/* <div className="mb-3">
                  <label>Entry price</label>
                  <input
                    type="number"
                    className="form-control"
                    value={price}
                    readOnly
                  />
                </div> */}

                    <div className="mb-3 ">
                      <label className="d-flex align-items-center">
                        {t("Stop Loss")}
                      </label>
                      <div style={{ position: "relative" }}>
                        <input
                          type="number"
                          className="form-control"
                          value={stopPrice}
                          name="stopPrice"
                          onChange={handleChange}
                        />
                        {stopPrice && (
                          <span
                            onClick={() => handleRemove("stopPrice")}
                            style={{
                              position: "absolute",
                              top: "12px",
                              right: "12px",
                              cursor: "pointer",
                            }}
                          >
                            X
                          </span>
                        )}
                        <div className="text-danger">{t(errors.stopPrice)}</div>
                      </div>
                    </div>

                    <div className="mb-3 ">
                      <label className="d-flex align-items-center">
                        {t("Take Profit")}
                      </label>
                      <div style={{ position: "relative" }}>
                        <input
                          type="number"
                          className="form-control"
                          value={takeProfit}
                          name="takeProfit"
                          onChange={handleChange}
                        />
                        {takeProfit && (
                          <span
                            onClick={() => handleRemove("takeProfit")}
                            style={{
                              position: "absolute",
                              top: "12px",
                              right: "12px",
                              cursor: "pointer",
                            }}
                          >
                            X
                          </span>
                        )}
                        <div className="text-danger">
                          {t(errors.takeProfit)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="place_order_fix">
                    <button
                      className="btn w100  mt-2 p-2"
                      onClick={modifyOrder}
                    >
                      Modify order
                    </button>
                  </div>
                </>
              )}
              {/* Partial Close Tab */}
              {activeTab === "partialClose" && (
                <div>
                  <div className="mb-3">
                    <label className="d-flex align-items-center">
                      {t("Volume to close")}
                    </label>
                    <div>
                      <div style={{ position: "relative" }}>
                        <input
                          type="number"
                          name="volumeToClose"
                          className={`form-control ${
                            isButtonDisabled ? "active" : ""
                          }`}
                          value={volumeToClose}
                          onChange={handleVolumeChange}
                          min="1"
                          step="1"
                        />
                      </div>
                    </div>
                    <small className="d-flex mt-2">
                      1 - {selectedRowData.totalQuantity}
                    </small>
                  </div>
                  <button
                    className="btn btn-warning w-100"
                    style={{ width: "100%" }}
                    onClick={closePartialOrder}
                    disabled={isButtonDisabled}
                  >
                    Close Order
                  </button>
                </div>
              )}
            </Modal.Body>
          </Modal>
        )}
        <SymbolModal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          segment="equity"
          added={selectedWatchList}
        />
        <div className="copy-right bg2 p-3 text-center br10 mb-3">
          © 2024 Dynamic Tech. All Rights Reserved{" "}
        </div>
      </DocumentMeta>
    </div>
  );
};

export default ForexExchangeEquity;
